import React from "react";
import Pagination from "@mui/material/Pagination";

const CustomPagination = ({ page = 1, setPage = () => {}, count = 24,limit}) => {

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Pagination
        count={count}
        size="small"
        page={page}
        limit={limit}
        onChange={handleChange}
      />
    </>
  );
};

export default CustomPagination;
