import * as yup from "yup";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import Error from "../../../../components/common/Error";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState } from "react";
import addIcon from "../../../../assets/images/comp_imgs/addnewlayer.png";
import deleteicon from "../../../../assets/images/comp_imgs/delete-icon.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { addDiscountApi, getMembershipData } from "../../../../api/membership";
import { errorToast, successToast } from "../../../../utils/toast";

const discountSchema = yup.object().shape({
  discount_alias_name: yup
    .string()
    .required(),
  membership_id: yup
    .string()
    .required(),
  start_date: yup
    .string()
    .required(),
  end_date: yup
    .string(),
  total_seats: yup
    .string()
    .required(),
  initiation_fees: yup
    .string(),
  tier: yup.array().of(yup.object().shape({
    discount_price: yup.string("Discount Price is required"),
    no_of_seats: yup.string(),
  }))
})

const AddDiscount1 = () => {
  const navigate = useNavigate();
  const [membershipList, setMembershipList] = useState([])
  const [membershipId, setMembershipId] = useState("")


  useEffect(() => {
    getMembershipList()
  }, [])

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tier: [
        {
          discount_price: "",
          no_of_seats: ""
        },
      ],
    },
    resolver: yupResolver(discountSchema),
  });


  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    update,
  } = useFieldArray({
    control,
    name: "tier",
  });

  const getMembershipList = async () => {
    const response = await getMembershipData();
    if (response?.status_code == 200) {
      setMembershipList(response?.data || []);
    }
  };

  const addDiscount = async (values) => {
    let payload = {
    }
    Object.keys(values).forEach((key) => {
      if (key === "membership_id") {
        payload.membership_id = membershipId
      } else {
        payload[key] = values[key];
      }
    });
    const response = await addDiscountApi(payload)
    if (response?.status_code === 200) {
      successToast(response?.message)
      reset()
      navigate(-1)
    }
  }

  const onSubmit = handleSubmit((values) => {
    addDiscount(values);
  });


  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <Breadcrumb
          module={"Membership&Pricing"}
          subModule={"Discounts"}
          parent={"Discounts"}
          child={"Add Discounts"}
        />
        <form onSubmit={onSubmit}>
          <div className="flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4   hide_scroll  overflow-y-auto">
            <div className="bg-white flex flex-col  w-full  items-start pt-2 px-4 rounded-lg ">
              <b className="unlimited-heading mb-7 text-2xl ">Add Discount</b>
              <div className="text-neutral-900 text-sm font-normal leading-4 mt-6 ">
                Enter alias of discount
              </div>
              <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                <input
                  className="  w-[697px] max-w-full outline-none bg-white"
                  placeholder="Pre-order discount"
                  {...register("discount_alias_name")}
                />
                <Error
                  error={errors?.discount_alias_name}
                  message={errors?.discount_alias_name?.message}
                />
              </div>
              <div class="w-[360px] text-neutral-900 text-sm font-medium font-['Hauora'] leading-[17px]">Discount applies to</div>
              <div className='mt-2'>
                <Form.Select
                  className="form-control "
                  aria-label="Account Status"
                  {...register('membership_id', {
                    onChange: (e) => { setMembershipId(e.target.value) },
                  })}
                >
                  {membershipList?.map((item, index) => (
                    <option value={item?._id}>{item?.name}</option>
                  ))}
                </Form.Select>
                <Error
                  error={errors?.membership_id}
                  message={errors?.membership_id?.message}
                />
              </div>
              <div class="text-neutral-900 text-lg font-bold font-['Hauora']">Discount Period</div>
              <div className='flex'>
                <div className='start-date'>
                  <div class="w-[139px] text-neutral-800 text-sm font-semibold font-['Hauora'] leading-tight">Start date</div>
                  <div class="w-[147px] h-[60px] px-3.5 py-[18px] bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 inline-flex">
                    <input
                      className=''
                      type="date"
                      {...register("start_date")} />
                  </div>
                </div>
                <div className='end-date ml-6'>
                  <div class="w-[139px] text-neutral-800 text-sm font-semibold font-['Hauora'] leading-tight">End date</div>
                  <div class="w-[147px] h-[60px] px-3.5 py-[18px] bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 inline-flex">
                    <input
                      className=''
                      type="date"
                      {...register("end_date")} />
                  </div>
                </div>
              </div>
              <div className="tiers-data">
                <div class="text-neutral-900 text-lg font-bold font-['Hauora']">Discount Structure -  Tiered Seats</div>
                {fields?.map((field, index) => (
                  <>
                    <div key={field.id} className="">
                      <div className="">
                        {index === fields?.length - 1 && (
                          <div>
                            <img className="add-next-icon" alt="next-layer" src={addIcon} onClick={() => { append(index, { fields: '' }) }} />
                          </div>
                        )}
                        {fields?.length > 1 && (
                          <div>
                            <img className="delete-next-icon" alt="next-layer" src={deleteicon} onClick={() => { remove(index) }} />
                          </div>
                        )}
                      </div>
                      <div className="price1">
                        <div className="price2"> Discount Price</div>
                        <div className="first-name2">
                          <div className="form-general1">
                            <div className="frame-parent">
                              <div className="label-parent">
                                <div className="label3">$</div>
                                <img
                                  className="arrow-drop-down-icon1"
                                  alt=""
                                  src="/arrow-drop-down@2x.png"
                                />
                                <div className="frame-child"></div>
                              </div>
                              <div className="text">
                                <input
                                  name="effective-time"
                                  placeholder="00:00"
                                  className="text"
                                  type="number"
                                  {...register(`tier.${index}.discount_price`)}
                                />
                              </div>
                            </div>
                          </div>
                          <Error error={errors?.["tier"]?.[index]?.["price"]} />
                        </div>
                      </div>
                      <div className="price3">
                        <div className="price2">No.of seats</div>
                        <div className="first-name2">
                          <div className="form-general1">
                            <div className="frame-parent">
                              <div className="label-parent">
                                <div className="label3">$</div>
                                <img
                                  className="arrow-drop-down-icon1"
                                  alt=""
                                  src="/arrow-drop-down@2x.png"
                                />
                                <div className="frame-child"></div>
                              </div>
                              <div className="text">
                                <input
                                  name="effective-time"
                                  placeholder="00:00"
                                  className="text"
                                  type="number"
                                  {...register(`tier.${index}.no_of_seats`)}
                                />
                              </div>
                            </div>
                          </div>
                          <Error error={errors?.["tier"]?.[index]?.["no_of_seats"]} />
                        </div>
                      </div>
                    </div>

                  </>
                ))}
              </div>
              <div class="text-neutral-900 text-lg font-bold font-['Hauora']">Discount Structure - Initiation Fee</div>
              <div></div>
              <div class="w-[362px] h-[62px] justify-start items-center gap-5 inline-flex">
                <div class="text-neutral-800 text-sm font-semibold font-['Hauora'] leading-tight">Discounted Initiation Fee</div>
                <div class="w-[175px] flex-col justify-start items-start gap-1 inline-flex">
                  <div class="self-stretch h-[32px] px-3.5 py-5 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex">
                    <div class="self-stretch justify-start items-center gap-2 inline-flex">
                      <div class="justify-start items-center gap-1 flex">
                        <div class="text-black text-opacity-20 text-base font-normal font-['Hauora']">$</div>
                      </div>
                      <div className="text">
                        <input
                          name="effective-time"
                          placeholder="00:00"
                          className="text"
                          type="number"
                          {...register("initiation_fees")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ml-6 flex'>
                  <div class="text-neutral-800 text-sm font-semibold font-['Hauora'] leading-tight">No. of seats avaiable</div>
                  <div class="w-[175px] flex-col justify-start items-start gap-1 inline-flex">
                    <div class="self-stretch h-[32px] px-3.5 py-5 bg-white rounded-lg border border-stone-300 flex-col justify-start items-start gap-2.5 flex">
                      <div class="self-stretch justify-start items-center gap-2 inline-flex">
                        <div class="justify-start items-center gap-1 flex">
                          <div class="text-black text-opacity-20 text-base font-normal font-['Hauora']">$</div>
                        </div>
                        <div className="text">
                          <input
                            name=""
                            placeholder="00:00"
                            className="text"
                            type="number"
                            {...register("total_seats")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-container">
            <div className="footer-body">
              <div onClick={() => reset()} className="f-delete div-3">Delete</div>
              <div onClick={() => navigate(-1)} className="f-cancel div-4">Cancel</div>
              {/* <div className="f-draft div-5">Save Draft</div> */}
              <button type="submit" className="f-apply div-6">Apply Discount</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default AddDiscount1
