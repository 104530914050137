import React, { useState } from "react";

export default function PopUpLayout({ showModal, setShowModal, children }) {
  const [name, setName] = useState("");
  const [AlertMsg, setAlertMsg] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
    console.log("name", name);
  };

  const handleSubmit = (e) => {
    setAlertMsg("Please wait");

    setTimeout(() => {
      setAlertMsg("Form Submit Successully");
    }, 1000);
    setTimeout(() => {
      setAlertMsg("");
      setShowModal(false);
    }, 2000);
  };

  return (
    <>
      {!showModal ? (
        <>
          <div className="modal-pop justify-center  items-center flex overflow-x-hidden z_index duration-1000 overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto  mx-auto max-w-3xl  ">
              {children}
            </div>
          </div>
          {/* <div className="opacity-50 fixed inset-0 z-40"></div> */}
        </>
      ) : null}
    </>
  );
}
