import React from "react";

const Footer = ({ bg, button1, button2, button3, button4 }) => {
  return (
    <>
      <div className="footer-main-container">
        <div className="footer-body">
          {button1 && (
            <>
              <div className="f-delete cursor-pointer ">
                {button1 || "Delete"}
              </div>
            </>
          )}
          {button2 && (
            <>
              <div className="f-cancel cursor-pointer">
                {button2 || "Cancel"}
              </div>
            </>
          )}
          {button3 && (
            <>
              <div className="f-draft cursor-pointer">
                {button3 || "Save Draft"}
              </div>
            </>
          )}
          {button4 && (
            <>
              <div
                className={
                  bg === "black"
                    ? "f-apply apply-bg-black cursor-pointer"
                    : "f-apply apply-bg-red cursor-pointer"
                }
              >
                {button4 || "Apply Pricing"}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Footer;
