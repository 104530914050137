import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../../../components/common/Error";
import { addItem, editItem } from "../../../api/membership";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { errorToast, successToast } from "../../../utils/toast";

const itemSchema = yup.object().shape({
  name: yup.string().required(),
});

const AddItem = ({ open, setOpen, fetchItemList, id, name = "", setName }) => {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(itemSchema),
  });

  useEffect(() => {
    setValue("name", name);
  }, [name]);

  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const onSubmit = handleSubmit((values) => {
    handleAdd(values);
  });

  const handleAdd = async (values) => {
    let response = {};
    if (id) {
      response = await editItem({ ...values, id, gst: "" });
    } else {
      response = await addItem({ ...values, gst: "" });
    }
    if (response?.status_code === 200) {
      successToast(response?.message);
      fetchItemList();
      handleClose();
      reset();
    } else {
      errorToast(response?.message);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{id ? "Edit" : "Add"} Item</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            {...register("name")}
          />
          <Error error={errors?.name} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddItem;
