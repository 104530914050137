import React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";

function OutletPage() {
  const data = useSelector((state) => state.auth);
  const { loader } = useSelector((state) => state.loader);
  const isLogin = data?.auth?.token;
  return (
    <>
      <div className={`${isLogin ? "right-panel" : ""}`}>
        <Outlet />
        {/* spinner */}
        {loader && (
          <div className=" flex justify-center items-center sticky bottom-[262px] z-[999]">
            <Spinner className="h-16 w-16 text-gray-900/50" />
          </div>
        )}
      </div>
    </>
  );
}
export default OutletPage;
