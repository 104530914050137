import api from "../interceptor.js";

/*Pilot management*/

export const getAllPilots = async (payload) =>
  await api.get(
    `getAllPilots?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}`
  );

export const uploadFileImage = async (formData) =>
  await api.post(`uploadAnyFiles`, formData);

export const onAddPilots = async (payload) =>
  await api.post(`addPilot`, payload);

export const onviewPilots = async (payload) =>
  await api.get(`getPilotbyID?id=${payload?.id}`);

export const onUpdatePilots = async (payload) =>
  await api.put(`updatePilot`, payload);

export const ondeletePilots = async (payload) =>
  await api.get(`deletePilot?id=${payload?.id}`);

/*route management*/

export const getAllRoutes = async (payload) =>
  await api.get(
    `getAllRoutes?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}`
  );

export const onAddRoutes = async (payload) =>
  await api.post(`addRoute`, payload);

export const onviewRoutes = async (payload) =>
  await api.get(`getRoutebyID?id=${payload?.id}`);

export const onUpdateRoutes = async (payload) =>
  await api.put(`updateRoute`, payload);

export const ondeleteRoutes = async (payload) =>
  await api.get(`deleteRoute?id=${payload?.id}`);

/*flight management*/

export const getAllFlightSchedules = async (payload) =>
  await api.get(
    `getAllFlights?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}`
  );

export const onAddFlight = async (payload) =>
  await api.post(`addFlights`, payload);

export const ondeleteFlight = async (payload) =>
  await api.get(`deleteFlight?id=${payload?.id}`);

export const onviewFlight = async (payload) =>
  await api.get(`getFlightbyID?id=${payload?.id}`);

export const onUpdateFlight = async (payload) =>
  await api.put(`updateFlight`, payload);

/*location management*/

export const getAllLocation = async (payload) =>
  await api.get(
    `getLocation?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}`
  );

export const onAddLocation = async (payload) =>
  await api.post(`addLocation`, payload);

export const onviewLocation = async (payload) =>
  await api.get(`getLocationById?id=${payload?.id}`);

export const onUpdateLocation = async (payload) =>
  await api.put(`updateLocation`, payload);

export const ondeleteLocation = async (payload) =>
  await api.get(`deleteLocation?id=${payload?.id}`);
