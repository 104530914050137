import React from "react";

const GroupChat = () => {
  return (
    <div className="internal-chat-wrap">
      <div className="group-chat-wrap">
      <div className="left-ic">
        <img src="/asset/groups.svg" />
        <p>
        Internal Chat
        </p>
      </div>
      <div className="right-ic">
        <img src="/asset/add.svg" />
      </div>
      </div>
      <div className="group-chat-wrap user-g">
      <div className="left-ic">
        <img src="/asset/user-g.svg" />
        <p>
        Black Jet one
        </p>
      </div>
      </div>
    </div>
  );
};

export default GroupChat;
