import React, { useEffect, useRef, useState } from "react";
// import Breadcrumb from "../../../../components/console/customer-services/customer-mngmnt/Breadcrumb";
import ContainerHeading from "../../../../components/common/container-heading/ContainerHeading";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../../../../components/common/Error";
import { fetchCategoriesList } from "../../../../api/categories/categories";
import { addFaq, editFAQ, viewFAQ } from "../../../../api/content-mgmt/faq";
import CustomEditor from "../../../../components/common/CustomEditor";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import CustomLabel from "../../../dashboard/user-mgmt/CustomLabal";
import ReactPhoneInput from "react-phone-input-2";
import { Country } from "country-state-city";
import {
  addContact,
  editContact,
  viewContactUs,
} from "../../../../api/content-mgmt/adminContactUs";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import { errorToast, successToast } from "../../../../utils/toast";
import { alphabetValues } from "../../../../utils/regex";

// schema
const addContactSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Min 2 character required")
    .max(50, "Max limit reached"),
  email: yup
    .string()
    .required("Email is required")
    .max(20, "Max limit reached"),
  subject: yup
    .string()
    .required("Subject is required")
    .max(300, "Max limit reached"),
  message: yup
    .string()
    .required("Message is required")
    .max(120, "Max limit reached"),
  phone: yup.string().required("Phone is required"),
  phone_code: yup.string(),
});

const AddAdminContactUs = () => {
  const [contactData, setContactData] = useState();
  // router functions
  const navigate = useNavigate();
  const location = useLocation();
  // faq id from params
  const { id } = useParams();
  // ref
  const phoneInput = useRef(null);

  //   check path
  const isEdit =
    location?.pathname?.indexOf("edit-contact") > -1 ? true : false;

  // sideEffect
  useEffect(() => {
    if (isEdit) {
      getContact();
    }
  }, []);

  //useform functions
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addContactSchema),
  });

  //   get contact from id
  const getContact = async () => {
    let response = await viewContactUs(id);

    setValue("name", response?.data?.name);
    setValue("email", response?.data?.email);
    setValue("subject", response?.data?.subject);
    setValue("message", response?.data?.message);
    setValue("phone", response?.data?.phone);
    setValue("phone_code", response?.data?.phone_code);
    setContactData(response?.data);
  };

  // handle edit faq
  const handleEditContact = async (values) => {
    let payload = {
      id: id,
      name: values?.name,
      email: values?.email,
      subject: values?.subject,
      message: values?.message,
      phone: values?.phone,
      phone_code: values?.phone_code,
    };

    const response = await editContact(payload);
    if (response.status_code === 200) {
      navigate("/content/communication/contact-us");
      successToast(response?.message);
    } else {
      errorToast(response?.message);
    }
  };

  // handle add new contact
  const handleAddContact = async (values) => {
    console.log(values, "val");
    let payload = {
      name: values?.name,
      email: values?.email,
      subject: values?.subject,
      message: values?.message,
      phone: values?.phone,
      phone_code: values?.phone_code,
    };
    const response = await addContact(payload);
    if (response.status_code === 200) {
      successToast(response?.message);
      navigate("/content/communication/contact-us");
    } else {
      errorToast(response?.message);
    }
  };

  // form submit function
  const onSubmit = handleSubmit((values) => {
    if (isEdit) handleEditContact(values);
    else handleAddContact(values);
  });

  // console
  console.log(errors, "errors");

  return (
    <>
        <div className="flex flex-col justify-center items-center  ">
          <div className=" w-full  hide_scroll  overflow-y-auto">
            {/* <Breadcrumb
              parent={"Admin Contact Us"}
              child={isEdit ? "Edit Contact" : "Add Contact"}
            /> */}
            <div className="mt-0">
              <ContainerHeading
                title={
                  isEdit ? "Upadte Contact Section" : " Add New Contact Section"
                }
              />
            </div>
            {/* forms */}
            <form onSubmit={onSubmit}>
              <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4 pl-4  ">
                {/* title */}
                <>
                  <div className="text-neutral-900 text-lg font-medium leading-4 mt-6 ">
                    Name
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full outline-none bg-white"
                      placeholder="Name"
                      {...register("name")}
                      onKeyDown={alphabetValues}
                    />
                  </div>
                  <Error error={errors?.name} message={errors?.name?.message} />
                </>

                {/* email */}
                <>
                  <div className="text-neutral-900 text-lg font-medium leading-4 mt-6 ">
                    Email
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="Email"
                      {...register("email")}
                    />
                  </div>
                  <Error
                    error={errors?.email}
                    message={errors?.email?.message}
                  />
                </>
                {/*  */}

                <div className="text-neutral-900 text-lg font-medium leading-4 mt-6 ">
                  Phone
                </div>
                <div className="">
                  <Controller
                    control={control}
                    name="phone"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactPhoneInput
                        ref={phoneInput}
                        value={`${contactData?.phone_code}${contactData?.phone}`}
                        onChange={(value, data, event, formattedValue) => {
                          onChange(value.slice(data.dialCode.length));
                        }}
                        containerClass=""
                        inputClass="intl-tel-input-custom w-100 country_common_input !h-[60px] "
                        inputExtraProps={{
                          required: true,
                          autoFocus: true,
                        }}
                        isValid={(value, country) => {
                          // let countryCode = "IN";

                          setValue("phone_code", country.dialCode);
                          // countryCode = country.iso2.toUpperCase();
                          if (value.match(/12345/)) {
                            return (
                              "Invalid value: " + value + ", " + country.name
                            );
                          } else if (value.match(/1234/)) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        country={"in"}
                        countryCodeEditable={false}
                        specialLabel={"Player Mobile Number"}
                        enableSearch={true}
                      />
                    )}
                  />
                </div>

                <Error error={errors?.phone} message={errors?.phone?.message} />
                {/* Subject */}
                <>
                  <div className="text-neutral-900 text-lg font-medium leading-4 mt-6 ">
                    Subject
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="Subject"
                      {...register("subject")}
                    />
                  </div>
                  <Error
                    error={errors?.subject}
                    message={errors?.subject?.message}
                  />
                </>
                {/* Message */}
                <>
                  <div className="text-neutral-900 text-lg font-medium leading-4 mt-6 ">
                    Message
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <textarea
                      rows={5}
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="message"
                      {...register("message")}
                    />
                  </div>
                  <Error
                    error={errors?.message}
                    message={errors?.message?.message}
                  />
                </>

                {/* footer-button */}
                <div className="flex flex-row mt-4">
                  <>
                    <div
                      className=" bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  cursor-pointer"
                      onClick={() => navigate(-1)}
                    >
                      <div className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 ">
                        Cancel
                      </div>
                    </div>
                  </>

                  <>
                    <div className=" bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  ">
                      <button
                        type="submit"
                        className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 cursor-pointer"
                      >
                        {isEdit ? "Update" : "Save"}
                      </button>
                    </div>
                  </>
                </div>

                {/*  */}

                {/*  */}
              </div>
            </form>
          </div>
        </div>
    </>
  );
};

export default AddAdminContactUs;
