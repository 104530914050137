import React from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarTypo = ({
  icon,
  title,
  url,
  type = "url",
  width = 30,
  height = 30,
  target = "_self",
}) => {
  const { pathname } = useLocation();
  let pathfromUrl = pathname.split("/");
  let pathFromTab = url.split("/").pop();
  // console.log(pathFromTab, "tab");
  // console.log(pathfromUrl, "url");

  return (
    <>
      <Link
        className={`flex gap-2 items-center cursor-pointer ${
          pathfromUrl.includes(pathFromTab) ? "bg-blue-gray-700" : ""
        }  rounded-md hover:bg-blue-gray-800  `}
        to={url}
        target={target}
      >
        {/* <div className="bg-gray-800 flex gap-2 items-center px-2 rounded-md"> */}
        {/* {type === "url" ? (
          <div className={`w-[${width}px] h-[${height}px] flex items-center justify-center`}>
            <img
              className={`w-[${width}px] h-[${height}px] object-contain aspect-square img-white`}
             
              src={icon}
              alt=""
            />
          </div>
        ) : (
          icon
        )} */}
        {/* <span className="img-white">{icon}</span> */}
        <p className="text-[12px] ">{title}</p>
        {/* </div> */}
      </Link>
    </>
  );
};

export default SidebarTypo;
