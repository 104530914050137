import React, { useEffect } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../../../../../components/common/Error";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import ContainerHeading from "../../../../../components/common/container-heading/ContainerHeading";
import {
  addCareer,
  getCareerbyID,
  updateCareer,
} from "../../../../../api/content-mgmt/careers";
import Breadcrumb from "../../../../../components/common/Breadcrumb";
import { errorToast, successToast } from "../../../../../utils/toast";
import { numberValidation } from "../../../../../utils/regex";
import { fetchCategoriesList } from "../../../../../api/categories/categories";

// schema
const CreateNewJobSchema = yup.object().shape({
  job_name: yup
    .string()
    .required("Job name is required")
    .max(60, "Max character limit reached"),
  job_type: yup
    .string()
    .required("Job type is required")
    .max(60, "Max character limit reached"),
  job_category: yup.string().required("Job type is required"),

  job_location: yup
    .string()
    .required("Job location is required")
    .max(60, "Max character limit reached"),
  description: yup
    .string()
    .required("Description is required")
    .max(3000, "Max character limit reached"),
  responsibilities: yup
    .string()
    .required("Responsibilities is required")
    .max(3000, "Max character limit reached"),
  skills_qualifications: yup
    .string()
    .required("Skills qualifications is required")
    .max(3000, "Max character limit reached"),
  starting_salary: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required("Starting salary is required")
    .max(10000000, "Max limit reached"),
  max_salary: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required("Max salary is required")
    .max(10000000, "Max limit reached"),
});

// job type
const jobType = ["Full-time", "Part-time"];

const CreateNewJob = () => {
  // router functions
  const navigate = useNavigate();
  const location = useLocation();
  // job id from params
  const { id } = useParams();

  //   check path
  const isEdit = location?.pathname?.indexOf("edit-job") > -1 ? true : false;

  // sideEffect
  useEffect(() => {
    if (isEdit) {
      getJob();
    }
  }, []);

  //useform functions
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateNewJobSchema),
  });

  //   get single job from id
  const getJob = async () => {
    let response = await getCareerbyID(id);
    setValue("job_name", response?.data?.job_name);
    setValue("job_type", response?.data?.job_type);
    setValue("job_location", response?.data?.job_location);
    setValue("description", response?.data?.description);
    setValue("responsibilities", response?.data?.responsibilities);
    setValue("skills_qualifications", response?.data?.skills_qualifications);
    setValue("starting_salary", response?.data?.starting_salary);
    setValue("max_salary", response?.data?.max_salary);
  };

  // get all cateogry

  // handle job edit
  const handleJobEdit = async (values) => {
    let payload = {
      career_id: id,
      job_name: values.job_name,
      job_type: values?.job_type,
      job_location: values?.job_location,
      responsibilities: values?.responsibilities,
      skills_qualifications: values?.skills_qualifications,
      starting_salary: values.starting_salary,
      max_salary: values.max_salary,
    };

    const response = await updateCareer(payload);
    if (response?.status_code === 200) {
      successToast(response?.message);
      navigate("/content/page-mgmt/careers/job-listing");
    } else if (response?.status_code === 400) {
      errorToast(response?.message);
    }
  };

  // handle add new job
  const handleAddNewJob = async (values) => {
    let payload = {
      job_name: values.job_name,
      job_type: values?.job_type,
      job_location: values?.job_location,
      description: values?.description,
      responsibilities: values?.responsibilities,
      skills_qualifications: values?.skills_qualifications,
      starting_salary: values?.starting_salary,
      max_salary: values?.max_salary,
      job_category: values?.job_category,
    };

    const response = await addCareer(payload);
    if (response.status_code === 200) {
      successToast(response?.message);
      navigate("/content/page-mgmt/careers/job-listing");
    } else {
      errorToast(response?.message);
    }
  };

  // form submit function
  const onSubmit = handleSubmit((values) => {
    if (isEdit) handleJobEdit(values);
    else handleAddNewJob(values);
  });

  // console
  // console.log(errors, "errors");
  // console.log(getValues(), "useform values");
  // console.log(categories, "Categories");

  return (
    <>
        <div className="flex flex-col justify-center items-center">
          <div className=" w-full   hide_scroll  overflow-y-auto">
            {/* <Breadcrumb
              module={"content management"}
              subModule={"Page Management"}
              parent={"Careers"}
              child={"Jobs"}
              subchild={isEdit ? "Edit Job" : "Add Job"}
            /> */}
            <div className="mt-0">
              <ContainerHeading
                className={"rounded-lg"}
                title={isEdit ? "Update Job Section" : " Add New Job Section"}
              />
            </div>
            {/* forms */}
            <form onSubmit={onSubmit}>
              <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-4 pl-4  ">
                {/* job_name */}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                    Job Name
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full outline-none bg-white"
                      placeholder="Enter job name"
                      {...register("job_name")}
                    />
                  </div>
                  <Error
                    error={errors?.job_name}
                    message={errors?.title?.job_name}
                  />
                </>

                {/* Job type */}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                    Job Type
                  </div>
                  <div className="items-start content-start flex-wrap flex justify-between gap-5 pr-20 pb-4 max-md:pr-5 mt-2">
                    <div className="self-stretch flex grow basis-[0%] flex-row flex-wrap items-stretch gap-3">
                      {jobType?.map((job_type, job_type_index) => {
                        return (
                          <>
                            {/*  */}
                            <div class="flex gap-10">
                              <div class="inline-flex items-center">
                                <label
                                  class="relative flex items-center p-3 rounded-full cursor-pointer"
                                  htmlFor={`html${job_type_index}`}
                                >
                                  <div className="items-center border border-[color:var(--Grey-800,#141414)] bg-neutral-50 flex  w-[21px] h-[21px] px-2.5 rounded-md border-solid">
                                    <input
                                      type="radio"
                                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-black text-gray-900 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:before:bg-gray-900 hover:before:opacity-100"
                                      id={`html${job_type_index}`}
                                      name="jobType"
                                      value={job_type}
                                      {...register("job_type")}
                                    />

                                    <span class="absolute text-gray-900 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-3.5 w-3.5"
                                        viewBox="0 0 16 16"
                                        fill="currentColor"
                                      >
                                        <circle
                                          data-name="ellipse"
                                          cx="8"
                                          cy="8"
                                          r="8"
                                        ></circle>
                                      </svg>
                                    </span>
                                  </div>
                                </label>
                                <label
                                  class="mt-px font-light text-gray-700 cursor-pointer select-none"
                                  htmlFor={`html${job_type_index}`}
                                >
                                  {job_type}
                                </label>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <Error
                    error={errors?.job_type}
                    message={errors?.job_type?.message}
                  />
                </>

                {/* job Category */}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                    Job Category
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <select
                      className="  w-[697px] max-w-full outline-none bg-white"
                      placeholder="Enter job name"
                      {...register("job_category")}
                    >
                      <option disabled>select cateogry</option>
                      <option>Frontend</option>
                      <option>Backend</option>
                    </select>
                  </div>
                  <Error
                    error={errors?.job_category}
                    message={errors?.title?.job_category}
                  />
                </>

                {/* job_location */}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-4 ">
                    Job Location
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="Enter job location"
                      {...register("job_location")}
                    />
                  </div>
                  <Error
                    error={errors?.job_location}
                    message={errors?.job_location?.message}
                  />
                </>
                {/* description*/}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                    Description
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <textarea
                      rows={6}
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="Enter description"
                      {...register("description")}
                    />
                  </div>
                  <Error
                    error={errors?.description}
                    message={errors?.description?.message}
                  />
                </>
                {/* responsibilities */}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                    Responsibilities
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <textarea
                      rows={6}
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="Enter responsibilities"
                      {...register("responsibilities")}
                    />
                  </div>
                  <Error
                    error={errors?.responsibilities}
                    message={errors?.responsibilities?.message}
                  />
                </>
                {/* skills_qualifications */}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                    Skills Qualifications
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <textarea
                      rows={6}
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="Enter skills qualifications"
                      {...register("skills_qualifications")}
                    />
                  </div>
                  <Error
                    error={errors?.skills_qualifications}
                    message={errors?.skills_qualifications?.message}
                  />
                </>
                {/*  Starting salary */}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                    Starting Salary
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="Enter starting salary"
                      {...register("starting_salary")}
                      onKeyDown={numberValidation}
                    />
                  </div>
                  <Error
                    error={errors?.starting_salary}
                    message={errors?.starting_salary?.message}
                  />
                </>
                {/* max_salary */}
                <>
                  <div className="text-neutral-900 text-lg font-bold leading-4 mt-6 ">
                    Max Salary
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <input
                      className="  w-[697px] max-w-full bg-white outline-0"
                      placeholder="Enter maximum salary"
                      {...register("max_salary")}
                      onKeyDown={numberValidation}
                    />
                  </div>
                  <Error
                    error={errors?.max_salary}
                    message={errors?.max_salary?.message}
                  />
                </>

                {/* footer-button */}
                <div className="flex flex-row mt-4">
                  <>
                    <div
                      className=" bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  cursor-pointer"
                      onClick={() => navigate(-1)}
                    >
                      <div className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 ">
                        Cancel
                      </div>
                    </div>
                  </>

                  <>
                    <div className=" bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  ">
                      <button
                        type="submit"
                        className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] mr-5 cursor-pointer"
                      >
                        {isEdit ? "Update" : "Save"}
                      </button>
                    </div>
                  </>
                </div>

                {/*  */}

                {/*  */}
              </div>
            </form>
          </div>
        </div>
    </>
  );
};

export default CreateNewJob;
