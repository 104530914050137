export const images = {
  boutique: process.env.PUBLIC_URL + "/asset/sidebar-icons/boutique.svg",
  common: process.env.PUBLIC_URL + "/asset/sidebar-icons/common.svg",
  CommunicationTemplates:
    process.env.PUBLIC_URL +
    "/asset/sidebar-icons/Communication templates 2-36-01.svg",
  dashboard:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/dashboard-01-01.svg",
  discounts:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Discounts-15-01.svg",
  existingMemberPriceChanges:
    process.env.PUBLIC_URL +
    "/asset/sidebar-icons/Existing Member Price Changes-20-01.svg",
  flightSchedules:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Flight Schedules-33-01.svg",
  flightManagement:
    process.env.PUBLIC_URL +
    "/asset/sidebar-icons/Flight's Management-28-01.svg",
  giftCard: process.env.PUBLIC_URL + "/asset/sidebar-icons/Gift Card-19-01.svg",
  giveaway: process.env.PUBLIC_URL + "/asset/sidebar-icons/Giveawy-26-01.svg",
  graph: process.env.PUBLIC_URL + "/asset/sidebar-icons/Graph-01-01.svg",
  guestPass:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Guest Passes-17-01.svg",
  inbox: process.env.PUBLIC_URL + "/asset/sidebar-icons/Inbox-05-01.svg",
  itemsPricing:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Items Pricing-12-01.svg",
  liveChat: process.env.PUBLIC_URL + "/asset/sidebar-icons/Live chat-06-01.svg",
  location: process.env.PUBLIC_URL + "/asset/sidebar-icons/Location-31-01.svg",
  membershipsPrice:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Membership Price-07-01.svg",
  membershipsPlans:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Memberships Plans-21-01.svg",
  memberships:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Memberships-22-01.svg",
  pageManagement:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Page Management-34-01.svg",
  paymentGateway:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Payment Gateway-27-01.svg",
  petPass: process.env.PUBLIC_URL + "/asset/sidebar-icons/Pet pass-13-01.svg",
  pilotProfile:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Pilot Profle-29-01.svg",
  plane: process.env.PUBLIC_URL + "/asset/sidebar-icons/Plane-30-01.svg",
  protege: process.env.PUBLIC_URL + "/asset/sidebar-icons/Protege-10-01.svg",
  resetVoucher:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Reset Voucher-14-01.svg",
  route: process.env.PUBLIC_URL + "/asset/sidebar-icons/Route-32-01.svg",
  subAdminManagement:
    process.env.PUBLIC_URL +
    "/asset/sidebar-icons/Sub-Admin Management-04-01.svg",
  surveys: process.env.PUBLIC_URL + "/asset/sidebar-icons/surveys.svg",
  unlimited:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Unlimited 2-23-01.svg",
  unlimitedElite:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/Unlimited Elite 2-24-01.svg",
  UnlimitedPlanMembership:
    process.env.PUBLIC_URL +
    "/asset/sidebar-icons/Unlimited Plan Membership-18-01.svg",
  userManagement:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/User Manegament-03-01.svg",
  homePage: process.env.PUBLIC_URL + "/asset/sidebar-icons/house (1).svg",
  aboutUs: process.env.PUBLIC_URL + "/asset/sidebar-icons/informaiton.svg",
  legal: process.env.PUBLIC_URL + "/asset/sidebar-icons/legal.svg",
  faq: process.env.PUBLIC_URL + "/asset/sidebar-icons/FAQ.svg",
  newsAndBlog: process.env.PUBLIC_URL + "/asset/sidebar-icons/newsblog.svg",
  mediaAndPress: process.env.PUBLIC_URL + "/asset/sidebar-icons/media.svg",
  investors: process.env.PUBLIC_URL + "/asset/sidebar-icons/investor.svg",
  careers: process.env.PUBLIC_URL + "/asset/sidebar-icons/careers.svg",
  contactUs: process.env.PUBLIC_URL + "/asset/sidebar-icons/phone.svg",
  sales: process.env.PUBLIC_URL + "/asset/sidebar-icons/sales.svg",
  announcement:
    process.env.PUBLIC_URL + "/asset/sidebar-icons/announcement.svg",
  genericPet: process.env.PUBLIC_URL + "/asset/Generic Pet.png",
  genericUser: process.env.PUBLIC_URL + "/asset/Generic User.png",
};
