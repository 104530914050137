import { Card, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import "react-toggle/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { onActiveUsers } from "../../../api/users";
import NoDataFound from "../../../components/common/NoDataFound";
import { errorToast, successToast } from "../../../utils/toast";

const TABLE_HEAD = [
  "Preferred Name",
  "Legal Name",
  "Email",
  "Mobile number",
  "Membership",
  "Action",
];

const TABLE_ROWS = [
  {
    name: "John Michael",
    job: "Manager",
    date: "23/04/18",
    status: "In Progress",
  },
];

export function AppUserManagement({ userList, getUserList, loader }) {
  const navigate = useNavigate();

  const onActive = async (id, status) => {
    let payload = {
      id,
      status: status === "active" ? "inactive" : "active",
    };
    const response = await onActiveUsers(payload);
    if (response?.status_code == 200) {
      successToast(response?.message || "Success");
      getUserList();
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  return (
    <Card className=" !z-5 overflow-x-auto rounded-none w-full">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
              >
                <Typography
                  variant="small"
                  color="black"
                  className=" leading-none font-semibold"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {userList.map((item, index) => {
            const isLast = index === userList.length - 1;
            const classes = isLast
              ? "py-2 px-3"
              : "py-2 px-3 border-b border-blue-gray-50 td-dot";

            return (
              <tr key={index}>
                <td
                  className={classes + " cursor-pointer"}
                  onClick={() => {
                    navigate(
                      `/dashboard/user-management/user-list/view-user-list/${item?.id}`
                    );
                  }}
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item?.preferredFirstName || "N/A"}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item?.fullName || "N/A"}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item?.email || "N/A"}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {item?.phone || "N/A"}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className={`font-normal ${
                      item?.isMember === true ? "bg-[#90CAF9]" : "bg-[#cccbca]"
                      // : status == "In Progress"
                      // ? "bg-[#B9F6CA]"
                      // : "bg-[#EAEAEA]"
                    } py-1 rounded-[90px] text-center w-[90px] `}
                  >
                    {item?.isMember == false ? "Free Preview" : "Member"}
                  </Typography>
                </td>
                <td className={classes}>
                  <div class="action-toggle">
                    <Toggle
                      checked={item?.status === "active" ? "active" : ""}
                      icons={false}
                      className={
                        item?.status == "inactive" ? "isDisable" : "active"
                      }
                      onChange={() => onActive(item?.id, item?.status)}
                    />
                    <FontAwesomeIcon
                      onClick={() => {
                        navigate(
                          `/dashboard/user-management/user-list/edit-user-list/${item?.id}`
                        );
                      }}
                      className="edit-btn ms-3"
                      icon={faPen}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {userList.length === 0 && !loader && <NoDataFound />}
    </Card>
  );
}
