// imports
import React, { useEffect } from "react";
import { useState } from "react";
import ContainerHeading from "../../../../components/common/container-heading/ContainerHeading";
import plus_icon from "../../../../assets/images/icons/plus_icon.png";
import delete_icon from "../../../../assets/images/icons/delete_icon.png";
import grid_icon from "../../../../assets/images/icons/grid_icon.png";
import copy_icon from "../../../../assets/images/icons/copy_icon.png";
import up_arrow_icon from "../../../../assets/images/icons/up_arrow_icon.png";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../../../../components/common/Error";
import UploadImage from "../../../../components/common/uploads/UploadImage";
import {
  addInvestors,
  viewInvestors,
} from "../../../../api/content-mgmt/investors";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import { errorToast, successToast } from "../../../../utils/toast";
import CustomSwitch from "../../../../components/common/CustomSwitch";
import { useSelector } from "react-redux";
// schema
const InvestorSchema = yup.object().shape({
  frame1_image: yup.mixed().required("File is required"),
  frame2_image: yup.mixed().required("File is required"),
  frame3_image: yup.mixed().required("File is required"),
  frame4_content: yup
    .string()
    .required("Content is Required")
    .max(200, "Max character limit reached"),
});

// main parent component
const Investors = () => {
  // redux statw
  const { loader } = useSelector((state) => state.loader);
  // local state
  const [isEditable, setisEditable] = useState(false);
  const [investorsData, setInvestorsData] = useState();

  // usefrom functions
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(InvestorSchema),
  });

  // console
  // console.log(errors, "errors");
  // console.log(homePageData, "homedataa");

  // get data on load
  useEffect(() => {
    getInvestorsData();
  }, []);

  // newtwork call to updata data
  const editInvestorsDetails = async (values) => {
    // console.log(values, "upate values fiorm data");
    // console.log(typeof values.frame1_image, "fram1type");
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "object") {
        formData.append(key, values[key][0]);
      } else if (key === "frame4_content") {
        formData.append(key, values[key]);
      } else {
        formData.append(key, investorsData[key]);
      }
    });
    const response = await addInvestors(formData);
    if (response?.status_code === 200) {
      successToast(response?.message);
      getInvestorsData();
    } else if (response?.status_code === 400) {
      errorToast(response?.message);
    }
    setisEditable(false);
  };

  // network call to get data
  const getInvestorsData = async () => {
    let response = await viewInvestors();
    if (response?.status_code === 200) {
      setValue("frame1_image", response?.data?.frame1_image);
      setValue("frame2_image", response?.data?.frame2_image);
      setValue("frame3_image", response?.data?.frame3_image);
      setValue("frame4_content", response?.data?.frame4_content);
    }
    setInvestorsData(response?.data);
  };

  //form submit handler
  const onSubmit = handleSubmit((values) => {
    if (isEditable) {
      editInvestorsDetails(values);
    } else errorToast("Not editable");
  });
  // console.log(errors, "error");
  // console.log(investorsData, "idata");

  return (
    <>
        <div className="flex flex-col justify-center items-center   ">
          <div className=" w-full  hide_scroll  overflow-y-auto">
            {/* <Breadcrumb
              module={"Content Management"}
              subModule={"Page management"}
              parent={"Investors"}
              child={""}
            /> */}
            <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-0 ">
              {/* header */}
              <ContainerHeading
                title={"Investors"}
                chipName={"Published"}
                bgColor="#B9F6CA"
              />

              {/* edit switch */}
              <div className="px-3 w-[100px] h-[100px]">
                <CustomSwitch
                  value={isEditable}
                  setValue={setisEditable}
                  toast={
                    isEditable
                      ? "Content is now non editable"
                      : "Content is now  editable"
                  }
                />
              </div>

              {!loader && (
                <form onSubmit={onSubmit}>
                  {/* frame wrapper */}
                  <>
                    <div
                      className="items-stretch self-stretch bg-white flex flex-col pb-4 px-4 border-solid 
                  w-[calc(100vw-400px)] "
                    >
                      <div
                        className=" border-2 border-black  w-full py-5 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mr-2.5
              "
                      >
                        <div className="items-start flex gap-4 mx-4 max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:mr-2.5">
                          <div className="text-neutral-800 text-lg font-bold self-stretch grow max-md:max-w-full">
                            Investors
                          </div>
                          {/* <>
                            <img
                              loading="lazy"
                              src={plus_icon}
                              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                              alt="add-icon"
                            />
                            <img
                              loading="lazy"
                              src={copy_icon}
                              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                              alt="copy-icon"
                            />
                            <img
                              loading="lazy"
                              src={grid_icon}
                              className="aspect-square object-contain object-center w-[22px] overflow-hidden self-center shrink-0 
                max-w-full my-auto"
                              alt="grid-icon"
                            />
                            
                            <img
                              loading="lazy"
                              src={delete_icon}
                              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                              alt="delete-icon"
                            />
                            <img
                              loading="lazy"
                              src={up_arrow_icon}
                              className="aspect-square object-contain object-center w-6 overflow-hidden shrink-0 max-w-full self-start"
                              alt="arrow-icon"
                            />
                          </> */}
                        </div>
                      </div>
                      <div className="border-t-[color:var(--Grey-Grey,#97A1A9)] border-2 border-black flex w-full flex-col  pl-4 pr-20 pt-2.5 pb-12 border-t border-solid items-start max-md:max-w-full max-md:pr-5">
                        {/* frame 1 */}
                        <>
                          {/* image */}
                          <>
                            <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                              Frame 1
                            </div>
                            <UploadImage
                              className=""
                              inputId={`frame1_image`}
                              imgId={`frame1_image_1`}
                              name={`frame1_image`}
                              src={investorsData?.frame1_image}
                              setValue={setValue}
                              register={register}
                              type="upload"
                              isEditable={isEditable}
                            />
                          </>
                          <Error
                            error={errors?.frame1_image}
                            message={errors?.frame1_image?.message}
                          />
                        </>
                        {/* frame 2 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />
                          {/* image */}
                          <>
                            <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                              Frame 2
                            </div>
                            <UploadImage
                              className=""
                              inputId={`frame2_image`}
                              imgId={`frame2_image_1`}
                              src={investorsData?.frame2_image}
                              name={`frame2_image`}
                              setValue={setValue}
                              register={register}
                              type="upload"
                              isEditable={isEditable}
                            />
                          </>
                          <Error
                            error={errors?.frame2_image}
                            message={errors?.frame2_image?.message}
                          />
                        </>

                        {/* frame 3 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />
                          {/* image */}
                          <>
                            <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                              Frame 3
                            </div>
                            <UploadImage
                              className=""
                              inputId={`frame3_image`}
                              imgId={`frame3_image_1`}
                              src={investorsData?.frame3_image}
                              name={`frame3_image`}
                              setValue={setValue}
                              register={register}
                              type="upload"
                              isEditable={isEditable}
                            />
                          </>
                          <Error
                            error={errors?.frame3_image}
                            message={errors?.frame3_image?.message}
                          />
                        </>

                        {/* frame 4 */}
                        <>
                          <div className=" my-4 flex border border-black h-[1px] w-full flex-col" />
                          <div className="text-neutral-800 text-lg font-bold mt-4 max-md:max-w-full max-md:mt-10">
                            Frame 4
                          </div>

                          {/* text */}
                          <>
                            <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                              Content
                            </div>
                            <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                              <textarea
                                rows={8}
                                className="  w-[697px] max-w-full outline-none bg-white hide_scroll"
                                placeholder="  Destination body"
                                disabled={isEditable ? false : true}
                                {...register("frame4_content")}
                              />
                            </div>
                            <Error
                              error={errors?.frame4_content}
                              message={errors?.frame4_content?.message}
                            />
                          </>
                        </>
                        {/*  */}
                      </div>
                    </div>
                  </>
                  {/* footer-button */}
                  <div
                    className=" bg-white flex  gap-1 w-full flex-row items-end justify-end  mr-2  mb-2  "
                    type="submit"
                  >
                    <button
                      type="submit"
                      className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch  bg-black text-white px-16 py-3 rounded-lg  ml-[18px] mr-5 cursor-pointer hover:font-semibold"
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}

              {/*  */}
            </div>
          </div>
        </div>
    </>
  );
};

export default Investors;
