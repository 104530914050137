import { Typography } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import CustomEditor from '../../components/common/CustomEditor';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Error from '../../components/common/Error';
import { addMember, editMember, getMembershipDetails } from '../../api/membership';
import { useNavigate, useParams } from 'react-router-dom';
import ContainerHeading from '../../components/common/container-heading/ContainerHeading';
import { errorToast } from '../../utils/toast';

const membershipSchema = yup.object().shape({
  name: yup.string().required(),
  // content: yup.string().required(),
  highlightsArray: yup.array().of(
    yup.object().shape({
      highlight: yup.string().required('Required'),
      strikeThroughHighlight: yup.string(),
    }),
  ),
  text: yup.string().required(),
});

const AddMembership = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [details, setDetails] = useState({});

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    setError,
    getValues,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      highlightsArray: [
        {
          highlight: '',
          strikeThroughHighlight: '',
        },
      ],
    },
    resolver: yupResolver(membershipSchema),
  });

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control,
    name: 'highlightsArray',
  });

  useEffect(() => {
    if (id) {
      initialise();
    }
  }, []);
  useEffect(() => {
    details?.highlightsArray?.forEach((field, index) => {
      let obj = {};
      Object.keys(field).forEach((key) => {
        if (key !== '_id') {
          obj[key] = field[key];
        }
      });
      update(index, obj);
    });
  }, [details, update]);

  const initialise = async () => {
    let response = await getMembershipDetails({ id });
    if (response?.status_code === 200) {
      setValue('name', response?.data?.name);
      setValue('content', response?.data?.content);
      setValue('text', response?.data?.text);
      setDetails(response?.data || {});
    }
  };

  const onSubmit = handleSubmit((values) => {
    handleAdd(values);
  });

  const handleAdd = async (values) => {
    let response = {};
    if (id) {
      response = await editMember({ ...values, id });
    } else {
      response = await addMember(values);
    }
    if (response?.status_code === 200) {
      navigate(`/prices/membership/new-memberships-price`);
    } else {
      errorToast(response?.message);
    }
  };

  return (
    <div className='bg-white w-[calc(100vw-200px)] rounded-lg px-4'>
      {/* <div className="flex items-center justify-between p-10">
          <h1 className="text-2xl font-semibold">Membership Plan details</h1>
          <span className="rounded-3xl bg-lime-400 py-2 px-4">Published</span>
        </div> */}
      <ContainerHeading title='Membership Plan details' chipName='Published' bgColor='#B9F6CA' />

      <form onSubmit={onSubmit} className='px-3'>
        <>
          <div className='text-neutral-900 text-lg font-normal leading-4 mt-6 '>
            Membership Plan Name
          </div>
          <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
            <input
              className='  w-[697px] max-w-full bg-white outline-0'
              placeholder='Name'
              {...register('name')}
            />
          </div>
          <Error error={errors?.name} message={errors?.name?.message} />
        </>

        <div className='flex items-center justify-between mt-10'>
          <h1 className='text-xl font-bold'>Content</h1>
        </div>
        <Typography variant='p' color='blue-gray' className='mt-6'>
          Plan inclusions
        </Typography>
        {/* <CustomEditor
            initialValue={details?.content}
            onChange={(value) => {
              setValue("content", value.target.getContent());
              trigger("content");
            }}
          /> */}
        {fields?.map((field, index) => (
          <div key={field.id} className='flex'>
            <div className='flex-[0.8]'>
              <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                <input
                  className='w-full max-w-full bg-white outline-0'
                  placeholder='Highlight'
                  {...register(`highlightsArray[${index}].highlight`)}
                />
                <Error error={errors?.highlightsArray?.[index]?.highlight} />
              </div>
              <div className='text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white  max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5'>
                <input
                  className='w-full max-w-full bg-white outline-0'
                  placeholder='Strike Through Text'
                  {...register(`highlightsArray[${index}].strikeThroughHighlight`)}
                />
              </div>
            </div>
            <div className='flex-[0.2] flex flex-col justify-around'>
              {index === fields?.length - 1 && (
                <button onClick={() => append({ highlight: '', strikeThroughHighlight: '' })}>
                  Add
                </button>
              )}
              {fields?.length > 1 && <button onClick={() => remove(index)}>Remove</button>}
            </div>
          </div>
        ))}
        {/* <Error error={errors?.content} /> */}

        <Typography variant='p' color='blue-gray' className='mt-6'>
          Bottom Text
        </Typography>
        <CustomEditor
          initialValue={details?.text}
          onChange={(value) => {
            setValue('text', value.target.getContent());
            trigger('text');
          }}
        />
        <Error error={errors?.text} />

        {/* footer-button */}
        <div className='flex flex-row justify-end '>
          <div className='flex flex-row w-fit'>
            <>
              <div
                className=' bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  cursor-pointer'
                onClick={() => navigate(-1)}
              >
                <div className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid  ml-[18px] mr-4 '>
                  Cancel
                </div>
              </div>
            </>
            <>
              <div className=' bg-white flex flex-col gap-1 w-full  items-start mr-2 rounded-lg mb-2  cursor-pointer'>
                <div className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-white text-black px-16 py-3 rounded-lg border-solid  ml-[18px] mr-4 '>
                  Preview
                </div>
              </div>
            </>

            <>
              <div className=' bg-white flex flex-col gap-1 w-full  items-start rounded-lg mb-2  '>
                <button
                  type='submit'
                  className='text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-stretch border-2 border-[color:var(--Grey-800,#141414)] bg-black text-white px-16 py-3 rounded-lg border-solid  ml-[18px] cursor-pointer'
                >
                  {'Publish to staging'}
                </button>
              </div>
            </>
          </div>
        </div>
        {/* <Button type="submit" className="bg-dark text-white p-2 px-4">
            Submit
          </Button>
          <Button
            type="button"
            onClick={() => navigate(-1)}
            className="bg-white text-dark p-2 px-4"
          >
            Back
          </Button> */}
      </form>
    </div>
  );
};

export default AddMembership;
