import React from "react";
import Breadcrumb from "../../../../components/console/customer-services/customer-mngmnt/Breadcrumb";
import CustomLabel from "../../../dashboard/user-mgmt/CustomLabal";
import SelectWithChips from "../../../dashboard/user-mgmt/SelectWithChips";

import ContainerHeading from "../../../../components/common/container-heading/ContainerHeading";
import Footer from "../../../../components/common/footer/Footer";

const FAQ = () => {
  return (
    <>
      <div>
        <div className="flex flex-col justify-center items-center px-4 w-[calc(100vw-264px)] mt-5 ">
          <Breadcrumb parent={"Page management"} child={"FAQs"} />
        </div>

        <div className="flex flex-col justify-center items-center px-4 w-[calc(100vw-264px)] mt-5 ">
          <div className="bg-white flex flex-col  w-full  items-start pt-2 px-4 rounded-lg hide_scroll max-h-[calc(100vh-100px)] overflow-y-auto ">
            <ContainerHeading
              title={"FAQs"}
              chipName={"In staging"}
              bgColor="#90CAF9"
            />
            {/* <div className="flex">
              {" "}
              <h1 className="text-[24px] font-semibold mt-4">FAQs </h1>
            </div> */}

            <div className="w-2/4 my-4">
              <h2 className="text-[#141414] text-[14px] font-bold">
                Navigation Locations
              </h2>

              <SelectWithChips />
            </div>
          </div>
          {/* footer */}
          <Footer
            bg={"black"}
            button1={"Cancel"}
            button2={"Preview"}
            button3={"Save draft"}
            button4={"Publish to staging"}
          />
          {/*  */}
        </div>
      </div>
    </>
  );
};

export default FAQ;
