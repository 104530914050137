import React from "react";

const Line = () => {
  return (
    <div className="pl-1 pr-4 py-2">
      <div className="border-b-[2px] border-[#616161]"></div>
    </div>
  );
};

export default Line;
