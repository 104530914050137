import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../../../components/common/Error";
import { addBoutique, addItem, editBoutique, editItem, getMembershipData } from "../../../api/membership";
import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MenuItem, Select, Switch } from "@mui/material";
import { duration } from "moment/moment";
const durationObj = {
    week: [1,2,3],
    month: [1,2,3,4,5,6,7,8,9,10,11,12],
}

const itemSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    card_title: yup.string().required("Card title is required"),
    card_content: yup.string().required("Card content is required"),
    product_set: yup.string().required("Product set is required"),
    is_giftcard: yup.boolean().default(false),
    membership: yup.string().when('is_giftcard', (is_giftcard, schema) => {
        return is_giftcard?.find(item => item) ? schema.required("Required") : schema;
    }),
    is_month: yup.string().when('is_giftcard', (is_giftcard, schema) => {
        return is_giftcard?.find(item => item) ? schema.required("Required") : schema;
    }),
    no_of_month: yup.string().when('is_giftcard', (is_giftcard, schema) => {
        return is_giftcard?.find(item => item) ? schema.required("No. of Month is required") : schema;
    }),
});

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const AddBoutique = ({ open, setOpen, fetchItemList, id, setMemberships, memberships }) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        setError,
        getValues,
        trigger,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(itemSchema),
    });

    const is_giftcard = watch("is_giftcard");
    const is_month = watch("is_month");
    const membership = watch("membership");
    const no_of_month = watch("no_of_month");

    useEffect(() => {
        if (Object.keys(id)?.length === 0) {
            reset()
        } else {
            console.log(id)
            Object.keys(id)?.forEach((key) => {
                setValue(key, id[key])
            })
            if (id?.membership || id?.no_of_month) {
                setValue("is_giftcard", true)
            }
        }
    }, [id])

    const handleClose = () => {
        setOpen(false)
        reset()
    };
    const onSubmit = handleSubmit((values) => {
        handleAdd(values)
    })

    

    const handleAdd = async (values) => {
        let response = {}
        const isGiftcard = values.is_giftcard
        delete values.is_giftcard
        if (id?._id) {
            let payload = {
                name: values?.name,
                card_title: values?.card_title,
                card_content: values?.card_content,
                product_set: values?.product_set
            }
            if (isGiftcard) {
                payload.membership = values.membership
                payload.no_of_month = values.no_of_month
            }
            response = await editBoutique({ ...payload, id: id?._id })
        } else {
            response = await addBoutique({ ...values });
        }
        if (response?.status_code === 200) {
            fetchItemList()
            handleClose();
            reset();
        }
    }

    console.log(errors)
    console.log(memberships, "ships")

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <div className="flex items-center justify-between">
                        <span>
                            {id._id ? "Edit" : "Add"} Item
                        </span>
                        <span>
                            <span className="text-sm">
                                Gift Card
                            </span>
                            <Switch {...label} checked={is_giftcard} onChange={(e) => {
                                setValue("is_giftcard", e.target.checked);
                                trigger("is_giftcard")
                            }} />
                        </span>
                    </div>
                </DialogTitle>
                <DialogContent className="w-[600px]">
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("name")}
                    />
                    <Error error={errors?.name} />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="card_title"
                        label="Card Title"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("card_title")}
                    />
                    <Error error={errors?.card_title} />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="product_set"
                        label="Product Set Price"
                        type="number"
                        fullWidth
                        variant="standard"
                        {...register("product_set")}
                    />
                    <Error error={errors?.product_set} />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="card_content"
                        label="Card Content"
                        type="text"
                        fullWidth
                        variant="standard"
                        {...register("card_content")}
                    />
                    <Error error={errors?.card_content} />
                    {
                        is_giftcard &&
                        <>
                            <Select
                                id="demo-simple-select"
                                label="Membership"
                                className="w-full my-2"
                                labelId="demo-simple-select-label"
                                defaultValue={membership || ""}
                                displayEmpty
                                {...register("membership")}
                            >
                                <MenuItem value={""}>Select Membership</MenuItem>
                                {memberships?.map((membership) => (
                                    <MenuItem value={membership?._id}>{membership?.name}</MenuItem>
                                ))}
                            </Select>
                            <Error error={errors?.membership} />
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <Select
                                        displayEmpty
                                        labelId="demo-simple-select-label2"
                                        id="demo-simple-select"
                                        label="Duration Type"
                                        className="w-full my-2"
                                        defaultValue={is_month || ""}
                                        {...register("is_month", {
                                            onChange:e=>setValue("no_of_month", "")
                                        })}
                                    >
                                        <MenuItem value={""}>Duration Type</MenuItem>
                                        <MenuItem value={true}>{"Week"}</MenuItem>
                                        <MenuItem value={false}>{"Month"}</MenuItem>
                                    </Select>
                                    <Error error={errors?.is_month} />
                                </div>
                                <div>
                                    <Select
                                        displayEmpty
                                        labelId="demo-simple-select-label3"
                                        id="demo-simple-select"
                                        label="Duration Type"
                                        className="w-full my-2"
                                        defaultValue={no_of_month || ""}
                                        {...register("no_of_month")}
                                    >
                                        <MenuItem value={""}>Duration</MenuItem>
                                        {durationObj[is_month ? "week":"month"]?.map((num)=>(
                                            <MenuItem value={num}>{num}</MenuItem>
                                        ))}
                                    </Select>
                                    <Error error={errors?.no_of_month} />
                                </div>
                            </div>
                            {/* <TextField
                                autoFocus
                                margin="dense"
                                id="product_set"
                                label="Number of month"
                                type="number"
                                fullWidth
                                variant="standard"
                                {...register("no_of_month")}
                            />
                            <Error error={errors?.no_of_month} /> */}
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AddBoutique