import React from "react";

const WrapperCard = ({ name = "Alfonso Philips" }) => {
  return (
    <>
      <div
        id="WrapperRoot"
        className="bg-white flex flex-row justify-between w-full h-20 items-start pt-6 px-6 rounded-lg"
      >
        <div className="text-2xl font-['Hauora'] font-semibold text-[#2e2e2e]">
          {name}
        </div>
        <div
          id="Chip"
          className="text-sm font-['Hauora'] leading-[20px] text-[#2e2e2e] bg-[#b9f6ca] flex flex-row justify-center mt-1 py-1 w-1/6 items-start rounded-[100px]"
        >
          Member
        </div>
      </div>
    </>
  );
};

export default WrapperCard;
