import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { socket } from "../../../utils/socket";
import { getTimeFormat } from "../../../utils/moment";

const Inbox = ({ group, sender_type, sender }) => {
  const chatBoxRef = useRef(null);
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("")
  const last = useRef(null)

  const scrollToBottom = () => {
    const div = last.current
    if (div) {
      div.scrollIntoView({ behavior: 'smooth', alignment: "bottom" });
    }
  };


  useEffect(() => {
    const initChat = () => {
      if (socket && group?._id && sender) {
        socket.emit("initChat", { sender, sender_type, receiver: group?._id });
        getOldChat()
      }
    }
    initChat()
  }, [group])

  useEffect(() => {
    const receiveMessageHandler = (data) => {
      console.log("receive message", data)
      if (data?.result) {
        setChats(chats => [...chats, data?.result]);
        scrollToBottom()
      }
    }
    socket.off("receiveMessage", receiveMessageHandler);
    socket.on("receiveMessage", receiveMessageHandler)
  }, [])

  const getOldChat = () => {
    if (socket) {
      console.log({ sender: group?._id, sender_type })
      socket.emit("getConversationList", { sender: group?._id, sender_type, receiver: group?._id })
      socket.on("getConversationList", data => {
        console.log("getConversationList", data?.result)
        setChats(data?.result || [])
      })
    }
  }

  const sendMessage = () => {
    if (message?.trim() && socket && group?._id) {
      console.log("sendMessage", { sender, receiver: group?._id, sender_type, message: message?.trim(), message_type: "text" })
      socket.emit("sendMessage", { sender, receiver: group?._id, sender_type, message: message?.trim(), message_type: "text" })
      setMessage("");
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []); // Scroll to bottom on component mount


  return (
    <div>
      <div className="inbox-wrapper">
        <div class="messages" ref={chatBoxRef}>
          <ul>
            {chats?.map((chat, ind) => (
              <>
                {chat?.sender === sender ?
                  <li ref={ind===chats?.length-1 ? last : null} class="replies">
                    <div className="reply-txt">
                      <p>{chat?.message || "NA"}</p>
                      <span>
                        {getTimeFormat(chat?.createdAt)}
                        <FontAwesomeIcon
                          className="check-icon"
                          icon={faCheckDouble}
                        />
                      </span>
                    </div>
                  </li>
                  :
                  <li ref={ind===chats?.length-1 ? last : null} class="sent">
                    <div className="inbox-profile">
                      <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" />
                      <span>{chat?.sender_name || "Guest User"}</span>
                    </div>
                    <div className="sent-txt">
                      <p>{chat?.message || "NA"}</p>
                      <span>{getTimeFormat(chat?.createdAt)}</span>
                    </div>
                  </li>
                }
              </>
            ))}
          </ul>
        <div ref={last} className="absolute bottom-0"></div>
        </div>
        <div class="message-input">
          <div class="wrap">
            <div className="emoji">
              <img src="./asset/emoji.svg" />
            </div>
            <input disabled={Object.keys(group)?.length===0 ?true:false} value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={(event) => {
              if (event.key === 'Enter' || event.keyCode === 13) {
                event.preventDefault();
                sendMessage()
              }
            }} type="text" placeholder="Type a Message" />
            <div className="right-icon">
              <img src="./asset/attach.svg" />
              <img onClick={sendMessage} src="./asset/send.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inbox;
