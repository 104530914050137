import api from "../interceptor";

// get legal listing
export const viewAllLegal = async (payload) =>
  await api.get(`/viewAllLegal?skip=${payload.skip}&limit=${payload.limit}`);

// add legal
export const addLegal = async (payload) => await api.post(`/addLegal`, payload);

// edit legal
export const editLegal = async (payload) =>
  await api.post(`/editLegal`, payload);

// delete legal
export const deleteLegal = async (payload) =>
  await api.post(`/deleteLegal`, payload);

// view legal on id
export const viewLegal = async (payload) =>
  await api.get(`/viewLegal?id=${payload}`);

// update  legal status on id
export const updateUserStatus = async (payload) =>
  await api.post(`/updateUserStatus`, payload);
