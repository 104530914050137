import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useOutlet } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import { ADMIN } from "../constants/constant";
import NotAuthorised from "../components/common/NotAuthorised";

const PrivateRoute = ({pagename, children}) => {
  const data = useSelector(state => state.auth)
  console.log(data,"data")
  const isLogin = data?.auth?.token;
  // const permissionsMap = data?.auth?.permissionsMap || [];
  const permissionsMap = data?.auth?.roles_array?.map((role)=>(role.role_name)) || [];
  const isAllowed = data?.auth?.type===ADMIN ? true : permissionsMap?.includes(pagename)
  if (!isLogin) {
    return <Navigate to="/" />;
  }
  if(!isAllowed){
    return <NotAuthorised />
  }
  return  <>{children}</>;
};

export default PrivateRoute;