import React from "react";
import { Card } from "@material-tailwind/react";

import CustomPagination from "../../../../components/common/CustomPagination";
import { AppContentListing } from "./AppContentListing";

const AppContent = () => {
  return (
    <div className="  flex justify-center items-center mx-auto w-[calc(100vw-264px)] mt-5  ">
      <div className="">
        <Card className=" overflow-x-scroll hide_scroll  rounded-none rounded-t-2xl w-[calc(100vw-300px)]">
          <div className="py-4">
            <div className="flex justify-between px-4 ">
              <div className="flex items-center gap-3">
                <h1 className="text-[24px] font-semibold">App Content</h1>
              </div>

              <div className="flex gap-5 justify-between  "></div>
            </div>
          </div>
        </Card>
        {<AppContentListing />}
        <Card className="py-5 rounded-none  rounded-b-2xl ">
          <span className="bg-[#E6E6E6] py-3  px-2 w-72 items-center ml-3  rounded-xl ">
            <CustomPagination />
          </span>
        </Card>
      </div>
    </div>
  );
};

export default AppContent;
