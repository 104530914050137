import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../../../components/common/Error";
import { addItem, addSale, editItem, editSale, getItemsBoutique } from "../../../api/membership";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { errorToast, successToast } from "../../../utils/toast";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
// import 'moment/locale/de';
import moment from "moment";

const itemSchema = yup.object().shape({
    id: yup.string().required("Required"),
    type: yup.string().required("Required"),
    discount_price: yup.string().required("Required"),
    sale_start_date_time: yup.string().required("Required"),
    sale_end_date_time: yup.string().required("Required")
});

const AddSale = ({ open, setOpen, fetchItemList, id, sale = "", setSale }) => {
    const [locale, setLocale] = useState('en-us');
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null);
    const [options, setOptions] = useState({})

    if (moment.locale() !== locale) {
        moment.locale(locale);
    }
    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        setError,
        getValues,
        trigger,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(itemSchema),
    });

    // trigger("type")
    const type = watch("type")

    useEffect(() => {
        fetchItemsBoutique();
    }, [sale]);

    const handleClose = () => {
        setOpen(false);
        reset();
    };
    const onSubmit = handleSubmit((values) => {
        handleAdd(values);
    });

    const handleAdd = async (values) => {
        let response = {};
        let payload = {};
        payload.id = values.id
        payload.type = values.type
        payload.discount_price = values.discount_price
        if (id) {

            response = await editSale(payload);
        } else {
            payload.flash_sale = true;
            payload.sale_start_date_time = values.sale_start_date_time
            payload.sale_end_date_time = values.sale_end_date_time
            response = await addSale(payload);
        }
        if (response?.status_code === 200) {
            successToast(response?.message);
            fetchItemList();
            handleClose();
            reset();
        } else {
            errorToast(response?.message);
        }
    };

    const fetchItemsBoutique = async () => {
        let response = await getItemsBoutique();
        if (response?.status_code === 200) {
            setOptions(response?.data?.find(item => item))
        }
        if (id) {
            setValue("type", sale?.type || "")
            setValue("discount_price", sale?.discount_price || "")
            setValue("sale_end_date_time", sale?.sale_end_date_time || "")
            setValue("sale_start_date_time", sale?.sale_start_date_time || "")
            setTimeout(() => {
                setValue("id", sale?._id || "")
            }, 0)
        }
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{id ? "Edit" : "Add"} Sale</DialogTitle>
                <DialogContent>

                    {id ?
                        <Box className="flex items-start justify-around flex-col">
                            <Typography variant="h6 text-normal font-bold">
                                Type
                            </Typography>
                            <Typography variant="p" className="capitalize">
                                {sale?.type || "NA"}
                            </Typography>
                            <Typography variant="h6 text-normal font-bold">
                                {sale?.type === "item" ? "Item" : "Boutique"}
                            </Typography>
                            <Typography variant="p" className="capitalize">
                                {options[sale?.type === "item" ? "itemsArr" : "boutiqueArr"]?.find((item) => (item?._id) === (sale?._id))?.name || "NA"}
                            </Typography>
                        </Box>
                        :
                        <>
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-id">Type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-id"
                                    id="demo-simple-select-id"
                                    label="Type"
                                    {...register("type")}
                                    disabled={id}
                                    defaultValue={sale?.type}
                                >
                                    {/* <MenuItem value={"membership"}>Membership</MenuItem> */}
                                    <MenuItem value={"item"}>Item</MenuItem>
                                    <MenuItem value={"boutique"}>Boutique</MenuItem>
                                </Select>
                            </FormControl>
                            <Error error={errors?.type} />
                            <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-type" className="capitalize">Select {type}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-type"
                                    id="demo-simple-select-type"
                                    label="Item"
                                    {...register("id")}
                                    disabled={id}
                                    defaultValue={sale?._id}
                                >
                                    {options && options[type === "item" ? "itemsArr" : "boutiqueArr"]?.map(item => (
                                        <MenuItem value={item?._id}>{item?.name || "NA"}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </>
                    }
                    {!id &&
                        <>
                            <Error error={errors?.type} />
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                                <InputLabel id="demo-simple-select-start">Start At</InputLabel>
                                <DateTimePicker className="w-full mb-2" value={startDate} onChange={e => {
                                    setStartDate(e)
                                    setValue("sale_start_date_time", e?.toISOString())
                                }} maxDateTime={endDate} />
                                <Error error={errors?.sale_start_date_time} />
                                <InputLabel id="demo-simple-select-end">End At</InputLabel>
                                <DateTimePicker className="w-full mb-2" value={endDate} onChange={e => {
                                    setEndDate(e)
                                    setValue("sale_end_date_time", e?.toISOString())
                                }} minDateTime={startDate} />
                                <Error error={errors?.sale_end_date_time} />
                            </LocalizationProvider>
                        </>
                    }
                    <TextField
                        autoFocus
                        margin="dense"
                        id="discount_price"
                        label="Discount Price"
                        type="number"
                        fullWidth
                        variant="standard"
                        {...register("discount_price")}
                    />
                    <Error error={errors?.discount_price} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AddSale;
