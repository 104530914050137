import { Button, Input, ButtonBase } from "@mui/material"
import { useState, Fragment } from "react"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CustomEditor from '../../../components/common/CustomEditor';

export default function CustomerServiceSignatures() {
  const [signatures, setSignatures] = useState([
    { label: "Michael Smith", signature: "<p>Michael S. Customer Experience & Outreach BLACK JET</p>" },
    { label: "Sarah Williams", signature: "" },
    { label: "James Davis", signature: "" },
    { label: "Daniel Wilson", signature: "" },
    { label: "Partnerships", signature: "" },
    { label: "Sales", signature: "" },
    { label: "Something Else", signature: "" },
    { label: "Support", signature: "" },
  ])

  return (
    <div className="customer-service-signatures-wrapper">
      <div className="customer-service-signatures-container">
        <span className="label">Customer Service Signatures</span>
        <div className="categories-wrapper">
          {signatures?.map(({ label, signature }, index) => {
            return (
              <div key={index} className="category-container">
                <div className="left-conatiner">
                  <span className={`label ${index === 0 ? '' : ''}`}>Name</span>
                  <Input placeholder="category" onChange={({ target: { value } }) => {
                    const filteredArray = signatures;
                    filteredArray.splice(index, 1, { label: value, signature });
                    setSignatures([...filteredArray])
                  }} value={label} />
                </div>
                <div className="right-conatiner">
                  <CustomEditor
                    value={signature}
                    onChange={(value) => {
                      const filteredArray = signatures;
                      filteredArray.splice(index, 1, { label, signature: value.target.getContent() });
                      setSignatures([...filteredArray])
                    }}
                  />
                </div>
                {/* <div className="category-action-container">
                  <ButtonBase>
                    {index === 0 ? <Fragment /> : <DeleteForeverIcon onClick={() => {
                      const filteredArray = signatures;
                      filteredArray.splice(index, 1);
                      setSignatures([...filteredArray])
                    }} />}
                  </ButtonBase>
                </div> */}
              </div>
            )
          })}
          <ButtonBase className="add-icon" onClick={() => setSignatures((prevState) => [...prevState, { label: "", signature: "" }])}>
            <img src="./asset/addCircle.svg" />
            <span>Customer Service Agent</span>
          </ButtonBase>
        </div >
      </div>
      <div className="action-container">
        <Button variant="outlined" className="secondary">Cancel</Button>
        <Button variant="contained" className="primary">Apply</Button>
      </div>
    </div>
  )
}