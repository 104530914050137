import { Card, Tooltip, Typography } from "@material-tailwind/react";
import useQueryParams from "../../hooks/useQueryParams";
import {
  getMembershipList,
  updateMembershipStatus,
} from "../../api/membership";
import { useEffect, useState } from "react";
import ActionTooltip from "../../components/members&pricing/ActionTooltip";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { errorToast, successToast } from "../../utils/toast";
import { generateConfirm } from "../../utils/reactConfirmAlert";
import ContainerHeading from "../../components/common/container-heading/ContainerHeading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPen,
  faTrash,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import NoDataFound from "../../components/common/NoDataFound";
import { useSelector } from "react-redux";
import { getDateFormat } from "../../utils/moment";

const TABLE_HEAD = [
  'Membership name',
  'Price',
  'Initiation Fee',
  'Next Price',
  'Next Initiation Fee',
  'Effective Date',
  'Status',
  'Action',
];

/**
 * @deprecated do not Use `TABLE_ROWS`.
 */
const TABLE_ROWS = [
  {
    name: 'John Michael',
    job: 'Manager',
    date: '23/04/18',
  },
  {
    name: 'Alexa Liras',
    job: 'Developer',
    date: '23/04/18',
  },
  {
    name: 'Laurent Perrier',
    job: 'Executive',
    date: '19/09/17',
  },
  {
    name: 'Michael Levi',
    job: 'Developer',
    date: '24/12/08',
  },
  {
    name: 'Richard Gran',
    job: 'Manager',
    date: '04/10/21',
  },
];

/**
 * Renders a table of membership plans with their details and action buttons.
 *
 * @return {JSX.Element} The JSX element representing the membership plans table.
 */
export function NewMembershipsPrice() {
  const { loader } = useSelector((state) => state.loader);
  const query = useQueryParams();
  const navigate = useNavigate();
  const [memberships, setMemberships] = useState(null);
  const skip = query.skip || 1;
  const limit = 10;

  const fetchMemberShipList = async () => {
    let response = await getMembershipList({ skip, limit });

    if (response?.status_code === 200) {
      setMemberships(response?.data || []);
    }
  };

  // const handleAdd = () => {
  //   navigate(`/prices/membership/add-memberships`);
  // };

  const onActive = async (id, status) => {
    let payload = {
      id,
      status: status === 'active' ? 'inactive' : 'active',
    };
    const response = await updateMembershipStatus(payload);
    if (response?.status_code === 200) {
      successToast(response?.message || 'Success');
      fetchMemberShipList();
    } else {
      errorToast(response?.message || 'Something Went Wrong');
    }
  };

  // const handleUpdate = async (id) => {
  //   let item = memberships?.find((i) => i._id === id) || {};
  //   let response = await updateMembershipStatus({
  //     id: item?._id,
  //     status: item?.status === 'active' ? 'inactive' : 'active',
  //   });
  //   if (response?.status_code === 200) {
  //     successToast(response?.message);
  //     fetchMemberShipList();
  //   }
  // };

  const handleEdit = async (id) => {
    navigate(`/prices/membership/edit-memberships/${id}`);
  };

  const handlePriceEdit = (membership) => {
    navigate(`/prices/membership/${membership?.name}/${membership?._id}`);
  };

  useEffect(() => {
    fetchMemberShipList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  if (!memberships) {
    return <NoDataFound />;
  }

  return (
    <>
      <Card className='h-[calc(100vh-180px)] !z-5 overflow-x-scroll hide_scroll rounded-none  rounded-b-2xl w-[calc(100vw-200px)]'>
        <ContainerHeading
          title={'Membership Plans'}
          // button="Add Membership"
          redirect={'/prices/membership/add-memberships'}
        />
        <table className='w-full min-w-max  table-auto text-left'>
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className='border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]'
                >
                  <Typography
                    variant='small'
                    color='blue-gray'
                    className=' leading-none font-semibold'
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {memberships.length > 0 &&
              memberships.map((membership, index) => {
                const isLast = index === TABLE_ROWS.length - 1;
                const classes = isLast ? 'py-2 px-3' : 'py-2 px-3 border-b    border-blue-gray-50';

                return (
                  <tr key={membership?._id}>
                    <td className={classes}>
                      <Typography variant='small' color='blue-gray' className='font-normal'>
                        {membership?.name || 'NA'}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant='small' color='blue-gray' className='font-normal'>
                        {/* {membership?.currentPrice || "NA"} */}

                        {membership?.currentPrice
                          ? `$${membership?.currentPrice}`
                          : "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant='small' color='blue-gray' className='font-normal'>
                        {membership?.initiationFees
                          ? `$${membership?.initiationFees}`
                          : "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        as='a'
                        href='#'
                        variant='small'
                        color='blue-gray'
                        className='font-medium'
                      >
                        {membership?.nextCurrentPrice
                          ? `$${membership?.nextCurrentPrice}`
                          : "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant='small' color='blue-gray' className='font-normal'>
                        {membership?.nextInitiationFees
                          ? `$${membership?.nextInitiationFees}`
                          : "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography variant='small' color='blue-gray' className='font-normal'>
                        {membership?.effectiveDate
                          ? `${getDateFormat(membership?.effectiveDate, "DD MMM YYYY")}`
                          : "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant='small'
                        color='blue-gray'
                        className={`font-normal ${
                          membership.status === 'Scheduled'
                            ? 'bg-[#90CAF9]'
                            : membership.status === 'Published'
                            ? 'bg-[#B9F6CA]'
                            : membership.status === 'Draft'
                            ? 'bg-[#FFF8E1]'
                            : membership.status === 'active'
                            ? 'bg-[#B9F6CA]'
                            : 'bg-[#EAEAEA]'
                        }  px-3 py-1 rounded-[100px] text-center`}
                      >
                        {membership.status}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <div class='action-toggle'>
                        <Toggle
                          checked={membership?.status === 'active' ? 'active' : ''}
                          icons={false}
                          className={membership?.status === 'inactive' ? 'isDisable' : 'active'}
                          onChange={() => onActive(membership?._id, membership?.status)}
                        />
                        <Tooltip content={'Edit'}>
                          <FontAwesomeIcon
                            onClick={() => {
                              handleEdit(membership?._id);
                            }}
                            className='edit-btn ms-3'
                            icon={faPen}
                          />
                        </Tooltip>
                        <Tooltip content={'Prices'}>
                          <FontAwesomeIcon
                            onClick={() => {
                              handlePriceEdit(membership);
                            }}
                            className='edit-btn ms-3 px-2'
                            icon={faDollarSign}
                          />
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div className='flex flex-row-reverse p-4'>
          {memberships.length === 0 && !loader && <NoDataFound />}
        </div>
      </Card>
    </>
  );
}
