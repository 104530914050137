import React,{useRef} from 'react'
import ImageUpload from '../../../../components/common/ImageUpload'
import Error from '../../../../components/common/Error'
import { useForm, Controller } from "react-hook-form";
import ReactPhoneInput from "react-phone-input-2";
import parsePhoneNumber from 'libphonenumber-js';
import { Country } from 'country-state-city';
import * as yup from "yup";
import { Button } from "@material-tailwind/react";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import Form from "react-bootstrap/Form"
import CustomLabel from '../../../dashboard/user-mgmt/CustomLabal';
// import { getDateFormat } from "../../../../../../utils/moment";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { onAddPilots } from '../../../../api/flight-management/flight';
import { errorToast } from '../../../../helper';

let countryCode = 'IN';

const Emergency = ({register,errors,handleSubmit,setValue,trigger,control,onSubmit}) => {
    const navigate = useNavigate();
    const phoneInput = useRef(null);

   

    return (
        <>
            <div className=" h-[600px] tab_scroll overflow-y-scroll">
                <form onSubmit={onSubmit} >
                    <div className="px-2 pt-0">
                        <h2 className="text-[20px] font-bold text-[#303637] py-4">
                            Emergency Contact
                        </h2>

                        {/* inputs  */}
                        <div className="flex justify-between w-full gap-8 pt-3">
                            <div className="w-full space-y-8">
                                <div className=" form-input">
                                    <CustomLabel label={"Full name"} />

                                    {/* <CustomerManagementInput /> */}
                                    <input
                                        className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                        type="text"
                                        placeholder='Enter Full Name'
                                        {...register("EmergencyName")}
                                    // autoFocus="off"
                                    />
                                    <Error error={errors?.EmergencyName} message={errors?.EmergencyName?.message} />

                                </div>
                                <div className="form-input">
                                    <CustomLabel label={"Email"} />

                                    {/* <CustomerManagementInput /> */}
                                    <input
                                        className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                        type="text"
                                        placeholder='Enter Email'
                                        {...register("EmergencyEmail")}
                                    // autoFocus="off"
                                    />
                                    <Error error={errors?.EmergencyEmail} message={errors?.EmergencyEmail?.message} />

                                </div>
                                <div className="">
                                    <CustomLabel label={"Phone number"} />
                                    <Controller
                                    className="mt-2"
                                        control={control}
                                        name="EmergencyPhone"
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <ReactPhoneInput className="mt-2"
                                                ref={phoneInput}
                                                onChange={(value, data, event, formattedValue) => {
                                                    // setDialCode(data.dialCode);
                                                    // setCountryCode(data.countryCode.toUpperCase());
                                                    // setValue("phone_code", data?.dialCode)
                                                    onChange(value.slice(data.dialCode.length))
                                                }}
                                                inputClass="intl-tel-input-custom w-100 country_common_input !h-[60px]"
                                                inputExtraProps={{
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                isValid={(value, country) => {
                                                    // setValue("code_phone", country.iso2);
                                                    // setValue("dial_code", country.dialCode);
                                                    setValue("phone_code", country.dialCode)
                                                    countryCode = country.iso2.toUpperCase();
                                                    if (value.match(/12345/)) {
                                                        return (
                                                            "Invalid value: " + value + ", " + country.name
                                                        );
                                                    } else if (value.match(/1234/)) {
                                                        return false;
                                                    } else {
                                                        return true;
                                                    }
                                                }}
                                                country={'in'}
                                                countryCodeEditable={false}
                                                specialLabel={"Player Mobile Number"}
                                                enableSearch={true}
                                            />
                                        )}
                                    />
                                    <Error error={errors?.EmergencyPhone} message={errors?.EmergencyPhone?.message} />
                                </div>
                    
                            </div>
                        </div>

                        <div className='flex ml-80 mt-5 px-20'>
                            <div className='px-3'>
                                <Button onClick={() => navigate(-1)} size="sm" variant="outlined" className="">Cancel</Button>

                            </div>
                            <div className='w-100'>
                                <Button type="submit" size="sm" variant="filled" className="" >Apply</Button>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Emergency