import React, { useEffect, useState } from "react";
import $ from "jquery";
import defaultVideo from "../../../assets/images/upload.png";

const UploadImage = ({
  title = "",
  inputId = "",
  src = "",
  alt = "",
  imgId = "",
  type = "upload",
  name = "",
  setValue = () => { },
  register,
  accept = "image/png, image/gif, image/jpeg, image/tiff,",
  name2 = "",
  isLoading,
  setLoading,
  extraName = "",
  index = 0,
  preview = [],
  isEditable = true,
  setPreview = () => { },
  trigger = () => { },
}) => {
  useEffect(() => {
    $(`#${inputId}`).change(function () {
      var curElement = $(`#${imgId}`);
      var reader = new FileReader();
      reader.onload = function (e) {
        // if(e?.target?.result?.indexOf("video/")>-1){
        //     curElement.attr("src",defaultVideo);
        if (name2) {
          setValue(name2, "video");
        } else if (e?.target?.result?.indexOf("video/") > -1) {
          curElement.attr("src", defaultVideo);
        }

        // }
        // else if(e?.target?.result?.indexOf("/json")>-1){
        //     curElement.attr("src",json);
        //     if(name2){
        //         setValue(name2,'jsonData')
        //     }
        // }
        else {
          curElement.attr("src", e.target.result);
          if (name2) {
            setValue(name2, "image");
          }
          if (extraName) {
            document.getElementById(extraName).src = e.target.result;
          }
        }
      };
      reader.readAsDataURL(this.files[0]);
    });
  }, []);
  return (
    <>
      {/* <label htmlFor={inputId}>
        <img className="image-upload" id={imgId} src={src} alt={alt} />
        {title}
      </label> */}

      {/* <input
        type="file"
        className="form-control image-upload-input"
        id={inputId}
        name={name}
        onChange={async (e) => {
          if (type === "single-upload") {
            let tempArr = [];
            preview?.map((item, i) => {
              if (i === index) {
                item = e.target.files[0];
              }
              tempArr.push(item);
            });
            setPreview(tempArr);
          }
          // setLoading(true)
          if (type === "upload") {
            setValue(name, e.target.files);
          } else if (type === "mix-file") {
            setValue(name, e.target.files);
          }
          // else{
          //     let response = await uploadImage(e.target.files[0])
          //     setValue(name,response)
          // }
          // setLoading(false)
          trigger(name);
        }}
        accept={accept}
      /> */}
      {/* <div className="">
        <label
          className="text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center self-stretch border border-black bg-white mt-2  py-3 rounded-lg border-solid  cursor-pointer"
          for={inputId}
        >
          Change Image
        </label>
      </div> */}
      {/*  */}
      <>
        <div className=" mt-4 text-neutral-800 text-base font-semibold leading-6 self-stretch max-md:max-w-full">
          {title || "Image"}
        </div>

        <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5 text-balance flex flex-col">
          <label htmlFor={isEditable ? inputId : ""}>
            <img
              className="image-upload w-[400px] h-[250px] "
              id={imgId}
              src={src}
              alt={alt}
            />
            {/* {title} */}
          </label>
          <input
            type="file"
            className="form-control image-upload-input"
            id={inputId}
            name={name}
            onChange={async (e) => {
              if (type === "single-upload") {
                let tempArr = [];
                preview?.map((item, i) => {
                  if (i === index) {
                    item = e.target.files[0];
                  }
                  tempArr.push(item);
                });
                setPreview(tempArr);
              }
              // setLoading(true)
              if (type === "upload") {
                setValue(name, e.target.files);
                trigger(name);
              } else if (type === "mix-file") {
                setValue(name, e.target.files);
              }
              // else{
              //     let response = await uploadImage(e.target.files[0])
              //     setValue(name,response)
              // }
              // setLoading(false)
              trigger(name);
            }}
            accept={accept}
          />
          <label
            for={isEditable ? inputId : ""}
            className={`text-neutral-900 text-center text-base font-medium leading-5 whitespace-nowrap justify-center items-center self-stretch border border-black bg-white mt-4 px-16 py-3 rounded-lg border-solid max-md:max-w-full max-md:px-5 ${isEditable ? "cursor-pointer" : "cursor-not-allowed"
              }`}
          >
            Add Image
          </label>
        </div>
      </>
      {/*  */}
    </>
  );
};

export default UploadImage;
