import React, { useEffect, useState } from "react";
import ContainerHeading from "../../../../../components/common/container-heading/ContainerHeading";
import CustomPagination from "../../../../../components/common/CustomPagination";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import { faEye, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  deleteCareer,
  getAllCareers,
  getCareerbyID,
} from "../../../../../api/content-mgmt/careers";
import { getDateFormat } from "../../../../../helper";
import PopUpLayout from "../../../../dashboard/user-mgmt/PopUpLayout";
import { useNavigate } from "react-router-dom";
import { generateConfirm } from "../../../../../utils/reactConfirmAlert";
import NoDataFound from "../../../../../components/common/NoDataFound";
import { errorToast, successToast } from "../../../../../utils/toast";
import { useSelector } from "react-redux";
// table heading
const TABLE_HEAD = [
  "Id",
  "Job Name",
  "Job Type",
  "Job Location",
  "Description",
  "Responsibilities",
  "Skills Qualifications",
  "Status",
  "Created At",
  "Action ",
];

const JobPostListing = () => {
  // values from redux
  const { loader } = useSelector((state) => state.loader);
  // navigate
  const navigate = useNavigate();
  // local state
  const [jobPostList, setJobPostList] = useState([]);
  const [open, setOpen] = useState(false);
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [pageCount, setPageCount] = useState();

  const [uniqueJobDetails, setUniqueJobDetails] = useState();

  // side effect
  useEffect(() => {
    getJobPostList();
  }, [skip]);

  // delete job
  const handleJobDelete = (id) => {
    generateConfirm("Are you sure ", "you want to delete?", id, async () => {
      let payload = { id };
      let response = await deleteCareer(payload);
      if (response?.status_code === 200) {
        successToast(response?.message);
        getJobPostList();
      } else {
        errorToast(response?.message);
      }
    });
  };

  // job detail on id
  const getJobDetail = async (id) => {
    let response = await getCareerbyID(id);
    setUniqueJobDetails(response?.data);
    setOpen(true);
  };

  // function for postedJob list
  const getJobPostList = async () => {
    let payload = {
      skip,
      limit,
      search,
    };
    let response = await getAllCareers(payload);
    setJobPostList(response?.data);
    const count = Math.ceil(response?.totalItems / limit);
    setPageCount(count);
  };

  return (
    <>
      <div className="bg-white flex flex-col gap-1 items-start pt-2 max-h-[calc(100vh-190px)] w-[calc(100vw-220px)]   rounded-none   hide_scroll  overflow-y-auto">
        <ContainerHeading
          title={"Job Posting"}
          button={"New Job"}
          redirect={"/content/page-mgmt/careers/job-listing/create-new"}
        />

        <div className="bg-white flex flex-col  w-full  items-start pt-2 px-2 rounded-lg ">
          {jobPostList?.length ? (
            <>
              <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg mt-2  ">
                {/* lisitng table */}
                <div>
                  <div
                    className=" !z-5 
              overflow-x-scroll hide_scroll rounded-none 
               
               "
                  >
                    {/*   */}
                    <table className="w-full min-w-max  table-auto text-left">
                      <thead>
                        <tr>
                          {TABLE_HEAD.map((head) => (
                            <th
                              key={head}
                              className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-semibold leading-none "
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {jobPostList?.map((job, index) => {
                          const isLast = index === 1;
                          const classes = isLast
                            ? "py-2 px-3  max-w-[300px]"
                            : "py-2 px-3  max-w-[300px] border-b    border-blue-gray-50";

                          return (
                            <tr key={job?._id} className={""}>
                              <td className={classes + " cursor-pointer"}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal overflow-hidden whitespace-nowrap text-ellipsis"
                                >
                                  {job?._id}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {job?.job_name}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {job?.job_type}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {job?.job_location}
                                </Typography>
                              </td>
                              <td className={`${classes} `}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal overflow-hidden whitespace-nowrap text-ellipsis"
                                >
                                  {job?.description}
                                </Typography>
                              </td>
                              <td className={`${classes} `}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal overflow-hidden whitespace-nowrap text-ellipsis"
                                >
                                  {job?.responsibilities}
                                </Typography>
                              </td>
                              <td className={`${classes} `}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal overflow-hidden whitespace-nowrap text-ellipsis"
                                >
                                  {job?.skills_qualifications}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className={`font-normal ${
                                    job?.status === "Scheduled"
                                      ? "bg-[#90CAF9]"
                                      : job?.status === "Published"
                                      ? "bg-[#B9F6CA]"
                                      : job?.status === "Draft"
                                      ? "bg-[#FFF8E1]"
                                      : job?.status === "active"
                                      ? "bg-[#B9F6CA]"
                                      : "bg-[#EAEAEA]"
                                  }  px-3 py-1 rounded-[100px] text-center`}
                                >
                                  {job?.status}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {getDateFormat(job?.job_post_date)}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <div className="flex flex-row gap-2">
                                  <div className="action-icon">
                                    <Tooltip content={"View"}>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          getJobDetail(job?._id);
                                        }}
                                        className="edit-btn"
                                        icon={faEye}
                                      />
                                    </Tooltip>
                                  </div>
                                  <div className="action-icon">
                                    <Tooltip content={"Edit"}>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          navigate(
                                            `/content/page-mgmt/careers/job-listing/edit-job/${job._id}`
                                          );
                                        }}
                                        className="edit-btn"
                                        icon={faPen}
                                      />
                                    </Tooltip>
                                  </div>

                                  <div className="action-icon">
                                    <Tooltip content={"Delete"}>
                                      <FontAwesomeIcon
                                        onClick={() => {
                                          handleJobDelete(job._id);
                                        }}
                                        className="edit-btn"
                                        icon={faTrash}
                                      />
                                    </Tooltip>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          ) : (
            !loader && <NoDataFound />
          )}
        </div>

        {/*  */}
        {/* <div className=" flex flex-col  w-full  items-start pt-2  rounded-lg ">
        <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  rounded-lg   "
        ></div>
      </div> */}

        {/* view Job modal */}
        {open && (
          <PopUpLayout setShowModal={setOpen}>
            <>
              <div className="bg-white w-[700px] h-[calc(100vh-60px)] p-5 overflow-y-auto hide_scroll rounded-xl ">
                <div className="flex justify-between ">
                  <h1 className="taxt-[#303637] text-[20px] font-semibold ">
                    View Job
                  </h1>
                  <svg
                    className="cursor-pointer"
                    onClick={() => {
                      setOpen(false);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                      fill="#212121"
                    />
                  </svg>
                </div>
                {/* heading */}

                {/*  */}
                <div className="flex flex-row gap-4">
                  <div className="text-neutral-900 text-base font-bold leading-4 mt-6 ">
                    Name:
                  </div>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 ">
                    {uniqueJobDetails?.job_name}
                  </div>
                </div>
                {/* body */}

                {/*  */}
                <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                  {/* sub-title */}
                  <>
                    <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                      <div>Created</div>
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="text-balance">
                        {getDateFormat(uniqueJobDetails?.created_at)}
                      </p>
                    </div>
                  </>

                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Description
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueJobDetails?.description}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Job Location
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueJobDetails?.job_location}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Job Post Date
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {getDateFormat(uniqueJobDetails?.job_post_date)}
                      </p>
                    </div>
                  </>

                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Job Type
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueJobDetails?.job_type}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Status
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueJobDetails?.status}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Responsibilities
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueJobDetails?.responsibilities}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Skills qualifications
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueJobDetails?.skills_qualifications}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Starting salary
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueJobDetails?.starting_salary}
                      </p>
                    </div>
                  </>
                  <>
                    <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                      Max Salary
                    </div>
                    <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                      <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                        {uniqueJobDetails?.max_salary}
                      </p>
                    </div>
                  </>
                </div>

                {/*  */}
              </div>
            </>
          </PopUpLayout>
        )}
      </div>
      {/* pagination */}
      <div className="bg-white flex flex-col gap-1 w-[calc(100vw-320px)]   items-end pt-2 px-2 rounded-b-2xl    ">
        {pageCount > 1 && (
          <Card className="py-2 rounded-none   ">
            <span className="bg-[#E6E6E6] py-3  px-2  items-center ml-3  rounded-xl ">
              <CustomPagination
                page={skip}
                setPage={setSkip}
                count={pageCount}
              />
            </span>
          </Card>
        )}
      </div>
    </>
  );
};

export default JobPostListing;
