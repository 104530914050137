import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Tooltip } from "@material-tailwind/react";
import { Card } from "react-bootstrap";
import Toggle from "react-toggle";
import { useNavigate } from "react-router-dom";
import { getAllAdmins, updateAdminStatus } from "../../api/subadmin/subadmin";
import { faPen, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "../../components/common/NoDataFound";
import { errorToast, successToast } from "../../utils/toast";
import CustomPagination from "../../components/common/CustomPagination";
import ContainerHeading from "../../components/common/container-heading/ContainerHeading";
import { useSelector } from "react-redux";

const TABLE_HEAD = [
  "First Name",
  "Last Name",
  "Email",
  "Phone",
  "Permissions",
  "Action",
];

const Subadmin = () => {
  // values from redux
  const { loader } = useSelector((state) => state.loader);
  // react-router
  const navigate = useNavigate();
  // local state
  const [pageCount, setPageCount] = useState(1);
  const [skip, setSkip] = useState(1);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [adminList, setAdminList] = useState([]);
  const [editable, setEditable] = useState(false);

  useEffect(() => {
    getAdminType();
  }, [skip]);

  const getAdminType = async () => {
    let payload = {
      skip,
      limit,
      search: "",
      role: "",
    };
    const response = await getAllAdmins(payload);
    if (response?.status_code == 200) {
      setAdminList(response?.data || []);
      setPageCount(Math.ceil(response?.totalItems / payload.limit) || 0);
    }
  };

  const onActive = async (id, status) => {
    let payload = {
      id,
      status: status === "active" ? "inactive" : "active",
    };
    const response = await updateAdminStatus(payload);
    if (response?.status_code === 200) {
      successToast(response.message || "Success");
      getAdminType();
    } else {
      errorToast(response?.message || "Error");
    }
  };

  return (
    <div>
        <div className="common-bg rounded-t-2xl">
          {/* <div className="p-3 d-flex justify-content-between">
            <h1 className="common-heading">Subadmin</h1>
            <button
              className="add-user-button"
              onClick={() => {
                navigate("/dashboard/sub-admin/add-sub-admin");
              }}
            >
              Add Subadmin
            </button>
          </div> */}
          {/* heading */}
          <ContainerHeading
            redirect={"/dashboard/sub-admin/add-sub-admin"}
            title={"Sub Admin"}
            button={"Sub Admin"}
          />
          <Card className=" !z-5 overflow-x-auto rounded-none">
            <table className="w-full  table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                    >
                      <Typography
                        variant="small"
                        color="black"
                        className="font-normal leading-none font-semibold"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {adminList?.map((item, index) => {
                  const isLast = index === adminList.length - 1;
                  const classes = isLast
                    ? "py-2 px-3 "
                    : "py-2 px-3 border-b border-blue-gray-50";

                  return (
                    <tr key={index}>
                      <td
                        className={classes + " cursor-pointer"}
                        // onClick={() => {
                        //     navigate("/dashboard/customer-management/view-user-list");
                        // }}
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.first_name || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.last_name || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.email || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {item?.phone || "N/A"}
                        </Typography>
                      </td>
                      <td className={classes}>
                        {item?.roles_array?.map((role, index) => (
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {role?.role_name}-{role?.role_status}
                          </Typography>
                        ))}
                      </td>
                      <td className={classes}>
                        <div class="action-toggle !flex">
                          <Tooltip content={"View"}>
                            <FontAwesomeIcon
                              onClick={() => {
                                {
                                  navigate(
                                    `/dashboard/sub-admin/edit-sub-admin/${item?._id}`
                                  );
                                  setEditable(false);
                                  setId(item?._id);
                                }
                              }}
                              className="edit-btn"
                              icon={faEye}
                            />
                          </Tooltip>
                          {/* <Tooltip content={"Delete"}>
                                                    <FontAwesomeIcon
                                                        // onClick={() => {
                                                        //     delete(item?._id);
                                                        // }}
                                                        className="delete-btn mx-2"
                                                        icon={faTrash}
                                                    />
                                                </Tooltip> */}
                          <Tooltip content={"Edit"}>
                            <FontAwesomeIcon
                              disabled={item?.status === "inactive"}
                              onClick={() => {
                                if (item?.status !== "inactive") {
                                  navigate(
                                    `/dashboard/sub-admin/edit-sub-admin/${item?._id}`
                                  );
                                  setEditable(true);
                                  setId(item?._id);
                                } else {
                                  errorToast(
                                    "Please active this subadmin first"
                                  );
                                }
                              }}
                              className="edit-btn mx-2"
                              icon={faPen}
                            />
                          </Tooltip>
                          <div class="action-toggle gap-4">
                            <Toggle
                              checked={
                                item?.status === "active" ? "active" : ""
                              }
                              icons={false}
                              className={
                                item?.status === "active"
                                  ? "active"
                                  : "isDisable"
                              }
                              onChange={() => onActive(item?._id, item?.status)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {adminList.length === 0 && !loader && <NoDataFound />}
          </Card>
        </div>
      {/* pagination */}

      <div className="bg-white flex flex-col gap-1 w-full   items-end pt-2 px-2   rounded-b-lg   ">
        {pageCount > 1 && (
          <Card className="py-2 rounded-none   ">
            <span className="bg-[#E6E6E6] py-3  px-2 w-auto items-center ml-3  rounded-xl ">
              <CustomPagination
                page={skip}
                setPage={setSkip}
                count={pageCount}
              />
            </span>
          </Card>
        )}
      </div>
    </div>
  );
};

export default Subadmin;
