import React from 'react'
import CustomLabel from '../../../dashboard/user-mgmt/CustomLabal';
import Error from '../../../../components/common/Error';
import ImageUpload from '../../../../components/common/ImageUpload';
import {
    faAdd,
    faClose,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Qualifications = ({ register, errors, handleSubmit, setValue, trigger, control, useFieldArray,onSubmit,setState,switchToNextTab }) => {

    const {
        fields: Certifications,
        append: CertificationsAppend,
        prepend: CertificationsPrepend,
        remove: CertificationsRemove,
        swap: CertificationsSwap,
        move: CertificationsMove,
        insert: CertificationsInsert,
        update: CertificationsUpdate,
    } = useFieldArray({
        control,
        name: "Certifications",
    });

    const {
        fields: SpecialQualifications,
        append: SpecialQualificationsAppend,
        prepend: SpecialQualificationsPrepend,
        remove: SpecialQualificationsRemove,
        swap: SpecialQualificationsSwap,
        move: SpecialQualificationsMove,
        insert: SpecialQualificationsInsert,
        update: SpecialQualificationsUpdate,
    } = useFieldArray({
        control,
        name: "SpecialQualifications",
    });


    return (
        <div className='h-[600px] overflow-y-auto'>
          <form onSubmit={onSubmit}>
            <div className="space-y-8 mt-3 pb-10">
                <h2 className="text-[20px] font-bold text-[#303637]">
                    Qualifications
                </h2>
                <div className="flex justify-between w-full gap-8 ">
                    <div className="w-full space-y-8">
                        <div className="w-full form-input">
                            <CustomLabel  label={"Flight School Attended"} />

                            {/* <CustomerManagementInput /> */}
                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="text"
                                placeholder='Enter License Number'
                                {...register("FlightSchoolAttended")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.FlightSchoolAttended} message={errors?.FlightSchoolAttended?.message} />

                        </div>
                        <div className="w-full">
                            <CustomLabel label={"Total Flight Hours"} />
                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="text"
                                placeholder='Enter Your Total Flight Hours'
                                {...register("TotalFlightHr")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.TotalFlightHr} message={errors?.TotalFlightHr?.message} />
                        </div>
                        <div className="">
                            <CustomLabel label={"Certifications"} />
                            {Certifications?.map((item, index) => (
                                <div className='mt-2' key={item?.id} >
                                    {Certifications?.length > 1 && (
                                        <button
                                            type="button"
                                            className="btn-cros"
                                            onClick={() => {
                                                CertificationsRemove(index)
                                                // setPreview(preview?.filter((item, i) => i !== index));
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faClose} />
                                        </button>
                                    )}
                                    <ImageUpload
                                        inputId={`certificatesUploadImage${index}`}
                                        imgId={`certificatesImage${index}`}
                                        src={"https://as1.ftcdn.net/v2/jpg/04/94/12/10/1000_F_494121047_MWi1oEYNest7yl1CardZO8jFtOwS7aRT.jpg"}
                                        // src={productImages[index]?.preview_type == "video" ? defaultVideo : productImages[index]?.image || uploades}
                                        className=""
                                        name={`Certifications.${index}.files`}
                                        setValue={setValue}
                                        register={register}
                                        type="url"
                                        // name2={`certificates.${index}.preview_type`}
                                        accept="image/*"
                                    />
                                    <div>

                                        {index === Certifications?.length - 1 && (
                                            <button
                                                type="button"
                                                className="common-btn btn-add add-user-button"
                                                onClick={() =>
                                                    // setPreview([...preview, ""])
                                                    CertificationsAppend(index,{files:""})
                                                }
                                            >
                                                <FontAwesomeIcon className="add-image me-2" icon={faAdd} /> Add More
                                            </button>
                                        )}

                                    </div>
                                </div>
                            ))}
                            <Error error={errors?.Certifications} message={errors?.Certifications?.message} />
                        </div>
                        <div className="">
                            <CustomLabel label={"Special Qualifications Certifications"} />
                            {SpecialQualifications?.map((item, index) => (
                                <div className='mt-2' key={item?.id} >
                                    {SpecialQualifications?.length > 1 && (
                                        <button
                                            type="button"
                                            className="btn-cros"
                                            onClick={() => {
                                                SpecialQualificationsRemove(index)
                                                // setPreview(preview?.filter((item, i) => i !== index));
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faClose} />
                                        </button>
                                    )}
                                    <ImageUpload
                                        inputId={`specialUploadImage${index}`}
                                        imgId={`specialImage${index}`}
                                        src={"https://as1.ftcdn.net/v2/jpg/04/94/12/10/1000_F_494121047_MWi1oEYNest7yl1CardZO8jFtOwS7aRT.jpg"}
                                        // src={productImages[index]?.preview_type == "video" ? defaultVideo : productImages[index]?.image || uploades}
                                        className=""
                                        name={`SpecialQualifications.${index}.files`}
                                        setValue={setValue}
                                        register={register}
                                        type="url"
                                        // name2={`certificates.${index}.preview_type`}
                                        accept="image/*"
                                    />
                                    <div>

                                        {index === SpecialQualifications?.length - 1 && (
                                            <button
                                                type="button"
                                                className="common-btn btn-add add-user-button"
                                                onClick={() =>
                                                    // setPreview([...preview, ""])
                                                    SpecialQualificationsAppend(index,{files:""})
                                                }
                                            >
                                                <FontAwesomeIcon className="add-image me-2" icon={faAdd} /> Add More
                                            </button>
                                        )}

                                    </div>
                                </div>
                            ))}
                            <Error error={errors?.SpecialQualifications} message={errors?.SpecialQualifications?.message} />
                        </div>
                    </div>
                    <div className="w-full space-y-8">
                        <div className="w-full">
                            <CustomLabel label={"Flight Hours by Aircraft Type"} />
                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="text"
                                placeholder='Enter Your date Of expiration'
                                {...register("FlightHrByAircraftType")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.FlightHrByAircraftType} message={errors?.FlightHrByAircraftType?.message} />
                        </div>
                        {/* <div className="w-full">
                            <CustomLabel label={"Special Qualifications"} />

                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="text"
                                placeholder='Special Qualifications'
                                {...register("SpecialQualifications")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.SpecialQualifications} message={errors?.SpecialQualifications?.message} />
                        </div>{" "} */}
                    </div>
                </div>
            </div>
            <div className="space-y-8 mt-8 pb-10">
                <h2 className="text-[20px] font-medium text-[#303637]">
                    Medical Certifications
                </h2>
                <div className="flex justify-between w-full gap-8 pt-5">
                    <div className="w-full space-y-8">
                        <div className="w-full form-input">
                            <CustomLabel label={"Medical Certificate Type"} />

                            {/* <CustomerManagementInput /> */}
                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="text"
                                placeholder='Medical Certificate Type'
                                {...register("MedicalCertType")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.MedicalCertType} message={errors?.MedicalCertType?.message} />

                        </div>
                        <div className="w-full">
                            <CustomLabel label={"Issuing Doctor"} />
                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="text"
                                placeholder='Issuing Doctor'
                                {...register("MeIssuingDoctor")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.MeIssuingDoctor} message={errors?.MeIssuingDoctor?.message} />
                        </div>
                        <div className="w-full">
                            <CustomLabel label={"Medical Restrictions if Any"} />

                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="text"
                                placeholder='Medical Restrictions if Any                    '
                                {...register("MeRestrictions")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.MeRestrictions} message={errors?.MeRestrictions?.message} />
                        </div>
                    </div>
                    <div className="w-full space-y-8">
                        <div className="w-full">
                            <CustomLabel label={"Date of Issue"} />
                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="date"
                                placeholder='Date of Issue'
                                {...register("MeDateOfIssue")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.MeDateOfIssue} message={errors?.MeDateOfIssue?.message} />
                        </div>
                        <div className="w-full">
                            <CustomLabel label={"Expiry Date"} />

                            <input
                                className='mt-2 form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                                type="date"
                                placeholder='Expiry Date'
                                {...register("MeExpiryDate")}
                            // autoFocus="off"
                            />
                            <Error error={errors?.MeExpiryDate} message={errors?.MeExpiryDate?.message} />
                        </div>{" "}
                    </div>
                </div>
            </div>
            <div className='w-100'>
                <Button size="sm" variant="filled" className="" onClick={()=>switchToNextTab()}>Next</Button>

              </div>
              </form>
        </div>
    )
}

export default Qualifications