import { Card, Typography } from "@material-tailwind/react";

const TABLE_HEAD = ["Item name", "Start Date", "End Date", "Status", "Action"];

const TABLE_ROWS = [
  {
    name: "John Michael",
    job: "Manager",
    date: "23/04/18",
  },
  {
    name: "Alexa Liras",
    job: "Developer",
    date: "23/04/18",
  },
];

export function ItemsPrice() {
  return (
    <Card className="h-[calc(100vh-180px)] overflow-x-scroll hide_scroll rounded-none  rounded-b-2xl w-[calc(100vw-300px)]">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100   px-14 text-start py-8"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {TABLE_ROWS.map(({ name, job, date }, index) => {
            const isLast = index === TABLE_ROWS.length - 1;
            const classes = isLast
              ? "py-6 px-14"
              : "py-6 px-14 border-b    border-blue-gray-50";

            return (
              <tr key={name}>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {name}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {job}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {date}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    as="a"
                    href="#"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                  >
                    Edit
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    as="a"
                    href="#"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="39"
                        height="39"
                        rx="7.5"
                        fill="white"
                      />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="39"
                        height="39"
                        rx="7.5"
                        stroke="#141414"
                      />
                      <path
                        d="M20 15.75C19.0335 15.75 18.25 14.9665 18.25 14C18.25 13.0335 19.0335 12.25 20 12.25C20.9665 12.25 21.75 13.0335 21.75 14C21.75 14.9665 20.9665 15.75 20 15.75ZM20 21.75C19.0335 21.75 18.25 20.9665 18.25 20C18.25 19.0335 19.0335 18.25 20 18.25C20.9665 18.25 21.75 19.0335 21.75 20C21.75 20.9665 20.9665 21.75 20 21.75ZM18.25 26C18.25 26.9665 19.0335 27.75 20 27.75C20.9665 27.75 21.75 26.9665 21.75 26C21.75 25.0335 20.9665 24.25 20 24.25C19.0335 24.25 18.25 25.0335 18.25 26Z"
                        fill="#212121"
                      />
                    </svg>
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
}
