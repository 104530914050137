import {
  SAVE_AUTH,
  SAVE_PERMISSIONS,
  SAVE_EMAIL,
} from "../../constants/constant";

export const saveAuth = (payload) => {
  return { type: SAVE_AUTH, payload };
};

export const saveLoader = (payload) => {
  return { type: "SET_LOADER", payload };
};
