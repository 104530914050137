import React, { useRef, useState } from 'react';
import ImageUpload from '../../../../components/common/ImageUpload';
import Error from '../../../../components/common/Error';
import { useForm, Controller } from 'react-hook-form';
import ReactPhoneInput from 'react-phone-input-2';
import parsePhoneNumber from 'libphonenumber-js';
import { Country } from 'country-state-city';
import * as yup from 'yup';
import { Button } from '@material-tailwind/react';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Form from 'react-bootstrap/Form';
import CustomLabel from '../../../dashboard/user-mgmt/CustomLabal';
// import { getDateFormat } from "../../../../../../utils/moment";
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { images } from '../../../../constants/images/image';
let countryCode = 'IN';

const PersonalInfo = ({
  register,
  errors,
  handleSubmit,
  setValue,
  trigger,
  control,
  onSubmit,
  value,
  state,
  setState,
  switchToNextTab,
  pilotData,
}) => {
  const navigate = useNavigate();
  const phoneInput = useRef(null);

  // const onSubmit = handleSubmit((values) => {

  //   })
  return (
    <>
      {/* <form onSubmit={onSubmit}> */}
      <div className='py-10 pt-0 h-full tab_scroll overflow-y-scroll'>
        <form className='px-2' onSubmit={onSubmit} >
          <div className='px-2 pt-0'>
            <h2 className='text-[20px] font-bold text-[#303637] py-4'>Personal Info</h2>
            <div className='flex justify-between'>
              <div>
                <h3 className='text-[#000000] font-bold text-[18px] ml-2'>Profile Photo</h3>

                <ImageUpload
                  className='profile_Image border cursor-pointer'
                  inputId={`profileimage`}
                  imgId={`profilepic`}
                  src={pilotData?.Photo || images.genericUser}
                  // src={citydetails || uploades}
                  // className="w-100"
                  name={`Photo`}
                  setValue={setValue}
                  register={register}
                  type='url'
                  trigger={trigger}
                />
                <Error error={errors?.Photo} message={errors?.Photo?.message} />
              </div>
            </div>

            {/* inputs  */}
            <div className='flex justify-between w-full gap-8 pt-5'>
              <div className='w-full space-y-8'>
                <div className='flex gap-4'>
                  <div className='w-full form-input'>
                    <CustomLabel label={'First name'} />

                    <input
                      className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                      type='text'
                      placeholder='Enter Full Name'
                      {...register('first_name')}
                      // autoFocus="off"
                    />
                    <Error error={errors?.first_name} message={errors?.first_name?.message} />
                  </div>
                  <div className='w-full form-input'>
                    <CustomLabel label={'Last name'} />

                    <input
                      className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                      type='text'
                      placeholder='Enter Full Name'
                      {...register('last_name')}
                      // autoFocus="off"
                    />
                    <Error error={errors?.last_name} message={errors?.last_name?.message} />
                  </div>
                </div>
                <div className='flex gap-3 w-full justify-end'>
                  <div>
                    <Button onClick={() => navigate(-1)} size='sm' variant='outlined' className=''>
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <Button type='submit' size='sm' variant='filled' className=''>
                      Apply
                    </Button>
                  </div>
                </div>

                {/* <div className=''>
                  <CustomLabel label={'Phone number'} />
                  <Controller
                    control={control}
                    name='phone'
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <ReactPhoneInput
                        ref={phoneInput}
                        value={`${pilotData?.country_code}${pilotData?.phone}`}
                        onChange={(value, data, event, formattedValue) => {
                          // setDialCode(data.dialCode);
                          // setCountryCode(data.countryCode.toUpperCase());
                          // setValue("phone_code", data?.dialCode)
                          onChange(value.slice(data.dialCode.length));
                        }}
                        inputClass='intl-tel-input-custom w-100 country_common_input !h-[60px]'
                        inputExtraProps={{
                          required: true,
                          autoFocus: true,
                        }}
                        isValid={(value, country) => {
                          // setValue("code_phone", country.iso2);
                          // setValue("dial_code", country.dialCode);
                          setValue('phone_code', country.dialCode);
                          countryCode = country.iso2.toUpperCase();
                          if (value.match(/12345/)) {
                            return 'Invalid value: ' + value + ', ' + country.name;
                          } else if (value.match(/1234/)) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        country={'in'}
                        countryCodeEditable={false}
                        specialLabel={'Player Mobile Number'}
                        enableSearch={true}
                      />
                    )}
                  />
                  <Error error={errors?.phone} message={errors?.phone?.message} />
                </div> */}
                {/* <div className='w-full'>
                  <CustomLabel label={'Date Of Birth'} />
                  <input
                    className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                    type='date'
                    // value={moment(pilotData?.dateOfBirth).format("DD-MM-YYYY")}
                    placeholder='Enter Your date Of birth'
                    {...register('dateOfBirth')}
                    max={new Date().toISOString().split('T')[0]}
                    // autoFocus="off"
                  />
                  <Error error={errors?.dateOfBirth} message={errors?.dateOfBirth?.message} />
                </div> */}
              </div>
              {/* <div className='w-full space-y-8'>
                <div className='w-full'>
                  <CustomLabel label={'Address'} />

                  <input
                    className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                    type='text'
                    placeholder='Address'
                    {...register('Address')}
                    // autoFocus="off"
                  />
                  <Error error={errors?.Address} message={errors?.Address?.message} />
                </div>
                <div className='w-full'>
                  <CustomLabel label={'LiIssuingAuthority'} />

                  <input
                    className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                    type='text'
                    placeholder='Enter email'
                    {...register('email')}
                    // autoFocus="off"
                  />
                  <Error error={errors?.email} message={errors?.email?.message} />
                </div>{' '}
                <div className='w-full'>
                  <CustomLabel label={'Nationality'} />
                  <Form.Select
                    className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px]'
                    aria-label='Gender'
                    // onChange={(e) => setStatus(e.target.value)}
                    {...register('Nationality')}
                  >
                    <option value=''>Choose Nationality</option>
                    <option value='Indian'>Indian</option>
                    <option value='Other'>Other</option>
                  </Form.Select>
                 
                  <Error error={errors?.Nationality} message={errors?.Nationality?.message} />
                </div>{' '}
              </div> */}
            </div>
            {/* <div className='space-y-8 mt-8 pb-10'>
              <h2 className='text-[20px] font-medium text-[#303637]'>Licensing Info</h2>
              <div className='flex justify-between w-full gap-8 pt-5'>
                <div className='w-full space-y-8'>
                  <div className='w-full form-input'>
                    <CustomLabel label={'License Number'} />

                    <input
                      className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                      type='text'
                      placeholder='Enter License Number'
                      {...register('LicenseNumber')}
                    />
                    <Error error={errors?.LicenseNumber} message={errors?.LicenseNumber?.message} />
                  </div>
                  <div className='w-full'>
                    <CustomLabel label={'Date Of Issue'} />
                    <input
                      className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                      type='date'
                      placeholder='Enter Your date Of issue'
                      {...register('LiDateOfIssue')}
                    />
                    <Error error={errors?.LiDateOfIssue} message={errors?.LiDateOfIssue?.message} />
                  </div>
                  <div className='w-full'>
                    <CustomLabel label={'Date Of Expiration'} />
                    <input
                      className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                      type='date'
                      placeholder='Enter Your date Of expiration'
                      {...register('LiExpirationDate')}
                    />
                    <Error
                      error={errors?.LiExpirationDate}
                      message={errors?.LiExpirationDate?.message}
                    />
                  </div>
                </div>
                <div className='w-full space-y-8'>
                  <div className='w-full'>
                    <CustomLabel label={'License Type'} />

                    <input
                      className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                      type='text'
                      placeholder='License Type'
                      {...register('LicenseType')}
                    />
                    <Error error={errors?.LicenseType} message={errors?.LicenseType?.message} />
                  </div>
                  <div className='w-full'>
                    <CustomLabel label={'Issuing Authority'} />

                    <input
                      className='form-control rounded-xl !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] !text-[#9A9A9A] text-[16px] '
                      type='text'
                      placeholder='Enter Issuing Authority'
                      {...register('LiIssuingAuthority')}
                    />
                    <Error
                      error={errors?.LiIssuingAuthority}
                      message={errors?.LiIssuingAuthority?.message}
                    />
                  </div>{' '}
                </div>
              </div>
            </div> */}

            {/* <div className='w-100'>
                <Button size="sm" variant="filled" className="" onClick={()=>switchToNextTab()}>Next</Button>
              </div> */}

            {/* <div className='flex ml-80  px-20'>
              <div className='px-3'>
                <Button onClick={() => navigate(-1)} size="sm" variant="outlined" className="">Cancel</Button>

              </div>
              <div className='w-100'>
                <Button type="submit" size="sm" variant="filled" className="" >Apply</Button>

              </div>
            </div> */}
          </div>
        </form>
      </div>
      {/* </form> */}
    </>
  );
};

export default PersonalInfo;
