import { Card, Tooltip, Typography } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import useQueryParams from '../../../hooks/useQueryParams';
import { useEffect, useState } from 'react';
import { getItemList, updateItemStatus } from '../../../api/membership';
import AddItem from './AddItem';
import { errorToast, successToast } from '../../../utils/toast';
import ContainerHeading from '../../../components/common/container-heading/ContainerHeading';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Toggle from 'react-toggle';
import { getDateFormat } from './../../../utils/moment';
import { useSelector } from 'react-redux';
import NoDataFound from '../../../components/common/NoDataFound';

const TABLE_HEAD = ['Item name', 'Price', 'Next Price', 'Next Effective Date', 'Status', 'Action'];


export function ItemsPricing() {
  // state from redux
  const { loader } = useSelector((state) => state.loader);
  const [open, setOpen] = useState(false);
  const query = useQueryParams();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const [name, setName] = useState('');
  const skip = query.skip || 1;
  const limit = 10;

  useEffect(() => {
    fetchItemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchItemList = async () => {
    let response = await getItemList({ skip, limit });
    if (response?.status_code === 200) {
      setItems(response?.data || []);
    }
  };

  const handleAdd = () => {
    setSelectedId('');
    setOpen(true);
    setName('');
  };

  // const handleUpdate = async (id) => {
  //   let item = items?.find((i) => i._id === id) || {};
  //   let response = await updateItemStatus({
  //     id: item?._id,
  //     status: item?.status === 'active' ? 'inactive' : 'active',
  //   });
  //   if (response?.status_code === 200) {
  //     successToast(response?.message);
  //     fetchItemList();
  //   }
  // };
  const onActive = async (id, status) => {
    console.log(id, status, 'asdfdsv');
    let payload = {
      id,
      status: status === 'active' ? 'inactive' : 'active',
    };
    const response = await updateItemStatus(payload);
    if (response?.status_code === 200) {
      successToast(response?.message || 'Success');
      fetchItemList();
    } else {
      errorToast(response?.message || 'Something Went Wrong');
    }
  };

  // const handleEdit = async (id) => {
  //   setName(items?.find((item) => item._id === id)?.name || '');
  //   setSelectedId(id);
  //   setOpen(true);
  // };

  const handlePriceEdit = (membership) => {
    navigate(`/prices/items/${membership?.name}/${membership?._id}`);
  };

  return (
    <Card className='h-[calc(100vh-180px)] overflow-x-scroll hide_scroll rounded-none  rounded-b-2xl w-[calc(100vw-200px)]'>
      {/* <ContainerHeading
        title={"Items Pricing"}
        button="Add Items Pricing"
        onButtonClick={handleAdd}
      /> */}
      <ContainerHeading title={'Items Pricing'} button='' onButtonClick={handleAdd} />
      <table className='w-full min-w-max  table-auto text-left'>
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className='border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]'
              >
                <Typography
                  variant='small'
                  color='blue-gray'
                  className=' leading-none font-semibold'
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const isLast = index === items.length - 1;
            const classes = isLast ? 'py-2 px-3' : 'py-2 px-3 border-b    border-blue-gray-50';

            return (
              <tr key={item?._id}>
                <td className={classes}>
                  <Typography variant='small' color='blue-gray' className='font-normal'>
                    {item?.name || 'NA'}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant='small' color='blue-gray' className='font-normal'>
                    {item?.currentPrice || 'NA'}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant='small' color='blue-gray' className='font-normal'>
                    {item?.nextCurrentPrice || 'NA'}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography variant='small' color='blue-gray' className='font-normal'>
                    {getDateFormat(item?.effectiveDate) || 'NA'}{' '}
                  </Typography>
                </td>
                {/* <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {getDateFormat(item?.effectiveDate) || "NA"}{" "}
                  </Typography>
                </td> */}
                <td className={classes}>
                  <Typography
                    variant='small'
                    color='blue-gray'
                    className={`font-normal ${
                      item.status === 'Scheduled'
                        ? 'bg-[#90CAF9]'
                        : item.status === 'Published'
                        ? 'bg-[#B9F6CA]'
                        : item.status === 'Draft'
                        ? 'bg-[#FFF8E1]'
                        : item.status === 'active'
                        ? 'bg-[#B9F6CA]'
                        : 'bg-[#EAEAEA]'
                    }  px-3 py-1 rounded-[100px] w-[100px] text-center`}
                  >
                    {item.status}
                  </Typography>
                </td>

                <td className={`${classes}`}>
                  <div class='action-toggle'>
                    <Toggle
                      checked={item?.status === 'active' ? 'active' : ''}
                      icons={false}
                      className={item?.status === 'inactive' ? 'isDisable' : 'active'}
                      onChange={() => onActive(item?._id, item?.status)}
                    />
                    {/* This feature may be enabled */}
                    {/* <Tooltip content={"Edit"}>
                      <FontAwesomeIcon
                        onClick={() => {
                          handleEdit(item?._id);
                        }}
                        className="edit-btn ms-3"
                        icon={faPen}
                      />
                    </Tooltip> */}
                    <Tooltip content={'Prices'}>
                      <FontAwesomeIcon
                        onClick={() => {
                          handlePriceEdit(item);
                        }}
                        className='edit-btn ms-3'
                        icon={faDollarSign}
                      />
                    </Tooltip>
                  </div>
                </td>

                {/* <td className={classes}>
                  <Typography
                    as="a"
                    // href="#"
                    variant="small"
                    color="blue-gray"
                    className="font-medium"
                  >
                    <ActionTooltip
                      handleEdit={handleEdit}
                      handleUpdate={handleUpdate}
                      id={item?._id}
                    />
                  </Typography>
                </td> */}
              </tr>
            );
          })}
        </tbody>
        {items.length === 0 && !loader && <NoDataFound />}
      </table>
      {/* <div className="flex flex-row-reverse p-4">
        <Fab onClick={handleAdd} color="info" aria-label="add">
          <AddIcon />
        </Fab>
      </div> */}
      <AddItem
        id={selectedId}
        name={name}
        setName={setName}
        fetchItemList={fetchItemList}
        open={open}
        setOpen={setOpen}
      />
    </Card>
  );
}
