import React from "react";
import addIcon from "../../../../assets/images/comp_imgs/addnewlayer.png";
import deleteicon from "../../../../assets/images/comp_imgs/delete-icon.jpg"
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Error from "../../../common/Error";
import { useParams } from "react-router-dom";
import { addPricing } from "../../../../api/membership";
import { successToast } from "../../../../utils/toast";



const NextScheduledPriceChange = ({ layer, inflation, register, errors, handleSubmit, control, handlePrice }) => {

  const {
    fields,
    append,
    prepend,
    remove,
    swap,
    move,
    insert,
    update,
  } = useFieldArray({
    control,
    name: "schedule_prices",
  });

  console.log(errors, "errors")


 

  return (
    <>
      {/* <form onSubmit={onSubmit}> */}
      <div className="pricing-container">
        {/* <div className="p-text-button">
          <img className="next-icon" alt="next-layer" src={addIcon} />
          <b className="label">Next Scheduled Price Change</b>
        </div> */}
        {fields?.map((field, index) => (
          <>

            <div key={field.id} className="p-effective-date-parent">
              <div className="flex image-icon">
                {index === fields?.length - 1 && (
                  <div>
                    <img className="add-next-icon" alt="next-layer" src={addIcon} onClick={() => { append(index, { fields: '' }) }} />
                  </div>
                )}
                {fields?.length > 1 && ( 
                  <div>
                    <img className="delete-next-icon" alt="next-layer" src={deleteicon} onClick={() => { remove(index) }} />
                  </div>
                )}
              </div>
              <div className="effective-date">
                <div className="effective-date1">Effective date</div>
                <div className="first-name-parent">
                  <div className="first-name">
                    <div className="form-general">
                      <div className="text-parent">
                        <input {...register(`schedule_prices.${index}.effectiveDate`)} className="form-control" type="date" />
                      </div>
                    </div>
                      <Error error={errors?.["schedule_prices"]?.[index]?.["effectiveDate"]} />
                  </div>
                  {/* <div className="first-name1">
                  <div className="form-general1">
                    <div className="text-wrapper">
                      <input
                        type="time"
                        name="effective-time"
                        placeholder="00:00"
                        className="text"
                      />
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
              <div className="price1">
                <div className="price2">Price</div>
                <div className="first-name2">
                  <div className="form-general1">
                    <div className="frame-parent">
                      <div className="label-parent">
                        <div className="label3">$</div>
                        <img
                          className="arrow-drop-down-icon1"
                          alt=""
                          src="/arrow-drop-down@2x.png"
                        />
                        <div className="frame-child"></div>
                      </div>
                      <div className="text">
                        <input
                          name="effective-time"
                          placeholder="00:00"
                          className="text"
                          type="number"
                          {...register(`schedule_prices.${index}.price`)}
                        />
                      </div>
                    </div>
                  </div>
                      <Error error={errors?.["schedule_prices"]?.[index]?.["price"]} />
                </div>
              </div>
              {inflation && (
                <>
                  <div className="price3">
                    <div className="price2">Initiation Fee</div>
                    <div className="first-name2">
                      <div className="form-general1">
                        <div className="frame-parent">
                          <div className="label-parent">
                            <div className="label3">$</div>
                            <img
                              className="arrow-drop-down-icon1"
                              alt=""
                              src="/arrow-drop-down@2x.png"
                            />
                            <div className="frame-child"></div>
                          </div>
                          <div className="text">
                            <input
                              name="effective-time"
                              placeholder="00:00"
                              className="text"
                              type="number"
                              {...register(`schedule_prices.${index}.initiationFees`)}
                            />
                          </div>
                        </div>
                      </div>
                            <Error error={errors?.["schedule_prices"]?.[index]?.["initiationFees"]} />
                    </div>
                  </div>
                </>
              )}
            </div>

          </>
        ))}
      </div>
      {/* </form> */}
    </>
  );
};

export default NextScheduledPriceChange;
