import ContainerHeading from "../../../../components/common/container-heading/ContainerHeading";
import React, { useEffect, useState } from "react";
import { Card, Tooltip, Typography } from "@material-tailwind/react";
import CustomPagination from "../../../../components/common/CustomPagination";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import { getDateFormat } from "../../../../helper";
import PopUpLayout from "../../../dashboard/user-mgmt/PopUpLayout";
import { generateConfirm } from "../../../../utils/reactConfirmAlert";
import NoDataFound from "../../../../components/common/NoDataFound";
import {
  contactUsList,
  deleteContactUs,
  viewContactUs,
} from "../../../../api/content-mgmt/adminContactUs";
import { errorToast, successToast } from "../../../../utils/toast";
import { useSelector } from "react-redux";

export const TABLE_HEAD = [
  "Name",
  "Email",
  "Subject",
  "Message",
  "Phone",
  "Created At",
  "Status",
  "Action",
];

const AdminContactUsListing = () => {
  // values from redux

  const { loader } = useSelector((state) => state.loader);
  // navigation
  const navigate = useNavigate();

  // local state
  const [adminContactUsList, setAdminContactUsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [skip, setSkip] = useState(1);
  const [uniqueContactDetails, setUniqueContactDetails] = useState();
  const [pageCount, setPageCount] = useState();
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getAdminContactUsListData();
  }, [skip, search]);
  const getAdminContactUsListData = async () => {
    let payload = {
      skip: skip,
      limit,
      search,
    };
    let response = await contactUsList(payload);
    setAdminContactUsList(response?.data);
    let count = Math.ceil(response?.totalItems / limit);
    setPageCount(count);
  };

  // details of single contact
  const contactDetail = async (id) => {
    setOpen(true);
    let response = await viewContactUs(id);
    setUniqueContactDetails(response?.data);
  };

  // delete contact on id
  const handleContactDelete = async (id) => {
    generateConfirm("Are you sure", "You want to delete", id, async () => {
      let payload = {
        id,
      };
      const response = await deleteContactUs(payload);
      if (response?.status_code === 200) {
        successToast(response?.message);
      } else if (response?.status_code === 400) {
        errorToast(response?.message);
      }
      getAdminContactUsListData();
    });
  };

  // status toggle function
  const onActive = async (id, status) => {
    let payload = {
      id,
      status: status === "active" ? "inactive" : "active",
    };
  };
  // console
  return (
    <>
        <div className="flex flex-col justify-center items-center   ">
          <div className=" w-full  hide_scroll  overflow-y-auto">
            <ContainerHeading
              className={"rounded-t-lg"}
              title={"Admin Contact Us"}
              button="Contact"
              redirect={"/content/communication/contact-us/add-contact"}
              search={true}
              searchValue={search}
              setSearchValue={setSearch}
            />
            {adminContactUsList?.length ? (
              <>
                <div className=" bg-white flex flex-col gap-1 w-full  items-start pt-2  ">
                  {/* lisitng table */}
                  <div>
                    <div
                      className=" !z-5 
              overflow-x-scroll hide_scroll rounded-none 
              w-[calc(100vw-320px)] 
               "
                    >
                      <table className="w-full min-w-max mx-2 table-auto text-left">
                        <thead>
                          <tr>
                            {TABLE_HEAD?.map((head) => (
                              <th
                                key={head}
                                className="border-b border-t border-blue-gray-100 px-3 text-start py-3  bg-[#d3d3d3]"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-semibold leading-none "
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {adminContactUsList?.map(
                            (
                              {
                                _id,
                                email,
                                message,
                                name,
                                phone,
                                phone_code,
                                subject,
                                created_at,
                                status,
                              },
                              index
                            ) => {
                              const isLast =
                                index === adminContactUsList.length - 1;
                              const classes = isLast
                                ? "py-2 px-3 max-w-[300px]"
                                : "py-2 px-3 max-w-[300px] border-b    border-blue-gray-50";

                              return (
                                <tr key={_id}>
                                  <td className={classes + " cursor-pointer"}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {name}
                                    </Typography>
                                  </td>
                                  <td className={`${classes} flex gap-1`}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className={`font-normal bg-[#F5F5F5] px-3 py-1 rounded-[100px] text-center`}
                                    >
                                      {email}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal  overflow-hidden whitespace-nowrap text-ellipsis"
                                    >
                                      {subject}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal  overflow-hidden whitespace-nowrap text-ellipsis"
                                    >
                                      {message}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {phone}
                                    </Typography>
                                  </td>

                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      {getDateFormat(created_at)}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className={`font-normal ${
                                        status === "Scheduled"
                                          ? "bg-[#90CAF9]"
                                          : status === "Published"
                                          ? "bg-[#B9F6CA]"
                                          : status === "Draft"
                                          ? "bg-[#FFF8E1]"
                                          : status === "active"
                                          ? "bg-[#B9F6CA]"
                                          : "bg-[#EAEAEA]"
                                      }  px-3 py-1 rounded-[100px] text-center`}
                                    >
                                      {status}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <div className="flex flex-row gap-2">
                                      <div class="action-toggle">
                                        <Toggle
                                          checked={
                                            status === "active" ? "active" : ""
                                          }
                                          icons={false}
                                          className={
                                            status === "inactive"
                                              ? "isDisable"
                                              : "active"
                                          }
                                          onChange={() => onActive(_id, status)}
                                        />
                                      </div>
                                      <div className="action-icon">
                                        <Tooltip content={"View"}>
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              contactDetail(_id);
                                            }}
                                            className="edit-btn"
                                            icon={faEye}
                                          />
                                        </Tooltip>
                                      </div>
                                      <div className="action-icon">
                                        <Tooltip content={"Edit"}>
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              navigate(
                                                `/content/communication/contact-us/edit-contact/${_id}`
                                              );
                                            }}
                                            className="edit-btn"
                                            icon={faPen}
                                          />
                                        </Tooltip>
                                      </div>
                                      <div className="action-icon">
                                        <Tooltip content={"Delete"}>
                                          <FontAwesomeIcon
                                            onClick={() => {
                                              handleContactDelete(_id);
                                            }}
                                            className="edit-btn"
                                            icon={faTrash}
                                          />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              !loader && <NoDataFound />
            )}
            {/* pagination */}

            <div className="bg-white flex flex-col gap-1 w-full   items-end pt-2 px-2   rounded-b-lg   ">
              {pageCount > 1 && (
                <Card className="py-2 rounded-none   ">
                  <span className="bg-[#E6E6E6] py-3  px-2 w-auto items-center ml-3  rounded-xl ">
                    <CustomPagination
                      page={skip}
                      setPage={setSkip}
                      count={pageCount}
                    />
                  </span>
                </Card>
              )}
            </div>
          </div>
        </div>

      {/* view FAQ modal */}
      {open && (
        <PopUpLayout setShowModal={setOpen}>
          <>
            <div className="bg-white w-[700px] p-5 h-[550px] overflow-y-auto hide_scroll rounded-xl ">
              <div className="flex justify-between ">
                <h1 className="taxt-[#303637] text-[20px] font-semibold ">
                  View Contact
                </h1>
                <svg
                  className="cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                    fill="#212121"
                  />
                </svg>
              </div>

              {/* body */}

              {/*  */}
              <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                {/*  */}

                <>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                    Name
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                      {uniqueContactDetails?.name}
                    </p>
                  </div>
                </>
                <>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                    Email
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                      {uniqueContactDetails?.email}
                    </p>
                  </div>
                </>
                <>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                    Subject
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                      {uniqueContactDetails?.subject}
                    </p>
                  </div>
                </>
                <>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                    Message
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                      {uniqueContactDetails?.message}
                    </p>
                  </div>
                </>
                <>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                    Phone
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                      {`${uniqueContactDetails?.phone_code} ${uniqueContactDetails?.phone}`}
                    </p>
                  </div>
                </>
                <>
                  <div className="flex gap-2 self-stretch text-neutral-900 text-sm font-medium leading-4  max-md:max-w-full">
                    <div>Created</div>
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="text-balance">
                      {getDateFormat(uniqueContactDetails?.created_at)}
                    </p>
                  </div>
                </>

                <>
                  <div className="text-neutral-900 text-sm font-medium leading-4 mt-6 max-md:max-w-full">
                    Status
                  </div>
                  <div className="text-neutral-400 text-base whitespace-nowrap border border-[color:var(--Grey-mid,#C0C0C0)] bg-white w-[697px] max-w-full justify-center mt-1 mb-2 pl-3.5 pr-16 py-3 rounded-lg border-solid items-start max-md:pr-5">
                    <p className="  w-[697px] max-w-full outline-none bg-white txt-wrap-balance">
                      {uniqueContactDetails?.status}
                    </p>
                  </div>
                </>
              </div>

              {/*  */}
            </div>
          </>
        </PopUpLayout>
      )}
    </>
  );
};

export default AdminContactUsListing;
