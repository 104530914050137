import { useState, useEffect } from 'react';
import { fetchCategoriesList } from '../../api/categories/categories';

const useFetchCategories = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null); // Clear any previous errors

      try {
        const response = await fetchCategoriesList(); // Assuming fetchCategoriesList exists
        setCategories(response?.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return { categories, isLoading, error, setCategories };
};

export default useFetchCategories;
