import React from "react";
import { useParams } from "react-router-dom";

const Test = () => {
  const { main } = useParams();

  return (
    <>
      <div>{main}</div>
    </>
  );
};

export default Test;
