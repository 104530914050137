import { useState } from "react";
import Breadcrumb from "../../../../components/console/customer-services/customer-mngmnt/Breadcrumb";
import WrapperCard from "../../../../components/console/customer-services/customer-mngmnt/WrapperCard";
import PilotTabs from "./PilotTabs";

export function PilotLayout() {
  const [state, setState] = useState("Personal Information");


  return (
    <>
        <div className="">
          <div className="w-full flex  justify-center items-center ">
            <div className="w-[calc(100vw-200px)] space-y-4 ">
              {/* <Breadcrumb parent={"Pilot Profile"} child={"Information"} /> */}
              <div
                id="WrapperRoot"
                className="bg-white flex flex-row justify-between w-full h-20 items-start pt-6 px-6 rounded-lg"
              >
                <div className="text-2xl font-['Hauora'] font-semibold text-[#2e2e2e]">
                  Add Information
                </div>

              </div>
            </div>
          </div>

          <div className="justify-center  mt-4  ">
            <PilotTabs setState={setState} state={state} />
          </div>
          {/* {state == "Customer details" && (
          <div className="w-full flex  justify-center items-center mt-10 ">
            <div className="w-[calc(100vw-300px)] space-y-4 ">
              <UserAccStatus/>
            </div>
          </div>
        )} */}
        </div>
    </>
  );
}
