import api from '../interceptor.js'

export const getAllAdmins = async (payload) => await api.get(`getAllAdminProfiles?skip=${payload.skip}&limit=${payload.limit}&name=${payload.search}&role=${payload.role}`);

export const getAllRoles = async () => await api.get(`get-roles`);

export const onAddSubadmin = async (payload) => await api.post(`register`,payload);

export const getAdminById = async (payload) => await api.get(`getAdminProfile?id=${payload.id}`);

export const updateAdmin = async (payload) => await api.post(`updateAdminProfile`,payload);

export const updateAdminStatus = async (payload) => await api.post(`updateAdminStatus`,payload);
