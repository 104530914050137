import api from "../interceptor";
// view FAQ listing
export const viewAllFAQs = async (payload) =>
  await api.get(
    `/viewAllFAQs?skip=${payload?.skip}&limit=${payload?.limit}&search=${payload?.search}`
  );

// add new FAQ
export const addFaq = async (payload) => await api.post(`/addFaq`, payload);

//  edit FAQ
export const editFAQ = async (payload) => await api.post(`/editFAQ`, payload);

// view FAQ on id
export const viewFAQ = async (payload) =>
  await api.get(`/viewFAQ?id=${payload}`);

// delete FAQ
export const deleteFaq = async (payload) =>
  await api.post(`/deleteFaq`, payload);


export const apiGetFaqQuestions = async (_id) => await api.get(`/getFaqQuestions?id=${_id}`);
