import React, { useEffect, useState } from "react";
import Error from "../../../components/common/Error";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { set, useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import CustomLabel from "../../dashboard/user-mgmt/CustomLabal";
import ImageUpload from "../../../components/common/ImageUpload";
import {
  onAddLocation,
  onUpdateLocation,
  onviewLocation,
} from "../../../api/flight-management/flight";
import { Button } from "@material-tailwind/react";
import { errorToast, successToast } from "../../../utils/toast";
import { alphabetValues } from "../../../utils/regex";

const routeSchema = yup.object().shape({
  city_name: yup.string().required("City Name is required"),
  state_name: yup
    .string()
    .required("State Name is required")
    .max(50, "Max limit reached"),
  image: yup.mixed().required("City image is required"),
  airport_abbreviation: yup
    .string()
    .required("This field is required")
    .max(50, "Max limit reached"),
});

const AddLocation = ({ open, setOpen, editable, id, modalType }) => {
  const [locationData, setLocationData] = useState("");
  useEffect(() => {
    if (editable) {
      viewLocation();
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(routeSchema),
  });

  const viewLocation = async () => {
    let payload = {
      id,
    };
    const response = await onviewLocation(payload);

    if (response?.status_code === 200) {
      setLocationData(response?.data);
      Object.keys(response?.data).forEach((key) => {
        setValue(key, response?.data[key]);
        // payload[key] = values[key];
      });
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  const addLocation = async (values) => {
    let payload = {};
    Object.keys(values).forEach((key) => {
      payload[key] = values[key];
    });
    const response = await onAddLocation(payload);
    if (response.status_code === 200) {
      // navigate("/dashboard/user-management")
      setOpen(false);
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  const onSubmit = handleSubmit((values) => {
    if (editable) {
      updateLocation(values);
    } else {
      addLocation(values);
    }
  });

  const updateLocation = async (values) => {
    let payload = {
      location_id: id,
    };
    Object.keys(values).forEach((key) => {
      if (
        key === "city_name" ||
        key === "state_name" ||
        key === "airport_abbreviation" ||
        key === "image"
      ) {
        payload[key] = values[key];
      }
    });
    const response = await onUpdateLocation(payload);
    if (response.status_code === 200) {
      setOpen(false);
      successToast(response?.message);
    } else {
      errorToast(response?.message || "Something Went Wrong");
    }
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="w-full mt-3">
          <CustomLabel label={"City Image"} />
          <div className="location-upload mt-2">
            <ImageUpload
              inputId={`cityUploadImage`}
              imgId={`cityCopyImage`}
              src={
                locationData?.image ||
                "https://as1.ftcdn.net/v2/jpg/04/94/12/10/1000_F_494121047_MWi1oEYNest7yl1CardZO8jFtOwS7aRT.jpg"
              }
              // src={productImages[index]?.preview_type == "video" ? defaultVideo : productImages[index]?.image || uploades}
              className=""
              name={`image`}
              setValue={setValue}
              register={register}
              type="url"
              trigger={trigger}
              // name2={`certificates.${index}.preview_type`}
              accept="image/svg+xml"
              disabled={modalType === "view" ? true : false}
            />
          </div>
          <Error error={errors?.image} message={errors?.image?.message} />
        </div>
        <div className="w-[100%] mt-3 ">
          <CustomLabel label={"City Name"} />
          <input
            className="form-control mt-2"
            type="text"
            placeholder="Enter City Name"
            onKeyDown={(e) => alphabetValues(e)}
            {...register("city_name")}
            disabled={modalType === "view" ? true : false}
          />
          <Error
            error={errors?.city_name}
            message={errors?.city_name?.message}
          />
        </div>
        <div className="w-[100%] mt-3 ">
          <CustomLabel label={"State Name"} />
          <input
            className="form-control mt-2"
            type="text"
            placeholder="Enter State Name"
            onKeyDown={(e) => alphabetValues(e)}
            {...register("state_name")}
            disabled={modalType === "view" ? true : false}
          />
          <Error
            error={errors?.state_name}
            message={errors?.state_name?.message}
          />
        </div>
        <div className="w-[100%] mt-3 ">
          <CustomLabel label={"Airport Abbrevation"} />
          <input
            className="form-control mt-2"
            type="text"
            placeholder="Enter Airport Abbrevation"
            onKeyDown={(e) => alphabetValues(e)}
            {...register("airport_abbreviation")}
            disabled={modalType === "view" ? true : false}
          />
          <Error
            error={errors?.airport_abbreviation}
            message={errors?.airport_abbreviation?.message}
          />
        </div>
        {modalType !== "view" && (
          <div className="flex mt-3 px-5">
            <div className="px-2">
              <Button
                onClick={() => setOpen(false)}
                size="sm"
                variant="outlined"
                className=""
              >
                Cancel
              </Button>
            </div>
            <div className="w-100 ">
              <Button type="submit" size="sm" variant="filled" className="">
                Apply
              </Button>
            </div>
          </div>
        )}
      </form>
    </>
  );
};

export default AddLocation;
