import api from "../interceptor";
// view contact listing
export const contactUsList = async (payload) =>
  await api.get(
    `/contact_us_list?skip=${payload?.skip}&limit=${payload.limit}&search=${payload.search}`
  );

// add new contact
export const addContact = async (payload) =>
  await api.post(`/contactUs`, payload);

//  edit contact
export const editContact = async (payload) =>
  await api.post(`/contactUs`, payload);

// view contact on id
export const viewContactUs = async (payload) =>
  await api.get(`/viewContactUs?id=${payload}`);

// delete contact
export const deleteContactUs = async (payload) =>
  await api.post(`/delete_contact_us`, payload);
