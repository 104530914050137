import { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import { useParams, useLocation } from "react-router-dom";
import PopUpLayout from "../../PopUpLayout";
import CustomerManagementInput from "../../CustomerManagementInput";
import CustomerManagementTextArea from "../../CustomerManagementTextArea";
import SelectWithChips from "../../SelectWithChips";
import CustomDropdown from "../../../../../../components/common/CustomDropdown";
import PetDetailsDropdown from "../../../../../../components/console/customer-services/customer-mngmnt/PetDetailsDropdown";
import CustomLabel from "../../CustomLabal";
import {
  getAllBreeds,
  getAllPets,
  onAddPets,
  onEditPet,
  stateLists,
  viewPet,
} from "../../../../../../api/users";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { set, useForm } from "react-hook-form";
import Error from "../../../../../../components/common/Error";
import ImageUpload from "../../../../../../components/common/ImageUpload";
import { Button } from "@mui/material";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoDataFound from "../../../../../../components/common/NoDataFound";
import moment from "moment";
import { numberValidation } from "../../../../../../utils/regex";
import { errorToast } from "../../../../../../utils/toast";
import { uploadImageTemplate } from "../../../../../../helper";
import { images } from "../../../../../../constants/images/image";

const TABLE_HEAD = [
  "Pet Photo",
  "Pet name",
  "Pet type",
  "Weight",
  "Assistance animal",
  "Libility signed",
  "Action",
];

const petSchema = yup.object().shape({
  pet_type: yup.string(),
  pet_name: yup
    .string()
    .required("Pet name is required")
    .max(60, "Max limit reached"),
  pet_weight: yup.string().max(10, "Max limit reached"),
  pet_liability_signature: yup.string().max(60, "Max limit reached"),
  bio: yup.string().max(120, "Max limit reached"),
  pet_breed: yup.array().of(yup.string()).min(1, "Minimum 1 required"),
  vets_name: yup.string().max(60, "Max limit reached"),
  birthday: yup.string(),
  pet_image: yup.mixed(),
  assistance_animal_proof: yup.mixed(),
  state: yup.string(),
  vets_license_no: yup.string().max(60, "Max limit reached"),
  vets_license_date: yup.string().max(60, "Max limit reached"),
  rabbies_vaccine_date: yup.string(),
  rabbies_vaccine_valid_to_date: yup.string(),
  distemper_vaccine_date: yup.string(),
  distemper_vaccine_valid_to_date: yup.string(),
});

const TABLE_ROWS = [
  {
    name: "Francis",
    type: "Golden retriever, Poodle",
    waight: "30 kg",
    Assistance: "Yes",
    Libility: "Yes",
    id: "1",
  },
  {
    name: "Francis",
    type: "Golden retriever, Poodle",
    waight: "30 kg",
    Assistance: "Yes",
    Libility: "Yes",
    id: "2",
  },
];

const people = [
  { id: 1, name: "Durward Reynolds", unavailable: false },
  { id: 2, name: "Kenton Towne", unavailable: false },
  { id: 3, name: "Therese Wunsch", unavailable: false },
  { id: 4, name: "Benedict Kessler", unavailable: true },
  { id: 5, name: "Katelyn Rohan", unavailable: false },
];

export function PetsDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [editId, setEditId] = useState();
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [petData, setPetData] = useState({});
  const [petsList, setPetsList] = useState([]);
  const [breedData, setBreedData] = useState([]);
  const [editable, setEditable] = useState(true);
  const [viewState, setViewState] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [selected, setSelected] = useState(people[0]);
  const [selectedOptions, setSelectedOptions] = useState();
  const editRoute =
    location?.pathname?.indexOf(
      `/dashboard/user-management/user-list/edit-user-list`
    ) > -1
      ? true
      : false;
  const viewRoute =
    location?.pathname?.indexOf(
      `/dashboard/user-management/user-list/view-user-list`
    ) > -1
      ? true
      : false;

  let currentDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (editRoute) {
      dropdownBreeds();
      stateDataList();
    }
  }, []);

  useEffect(() => {
    if (editRoute || viewRoute) {
      getPetsList();
    }
  }, [open]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(petSchema),
  });

  const petDetail = (id) => {
    setOpen(true);
    setViewState(true);
    viewPets(id);
    setEditable(false);
    // console.log("pet id", id);
  };

  const handleChange = (selected) => {
    setSelectedOptions(selected);
    let selectedPetData = selected.flatMap((item) => item.value);
    setValue("pet_breed", selectedPetData);
    trigger("pet_breed");
  };

  const getPetsList = async () => {
    let payload = {
      id,
      skip: 1,
      limit: 10,
      search: "",
    };
    const response = await getAllPets(payload);
    if (response?.status_code == 200) {
      setPetsList(response?.data || []);
      setCount(parseInt(response?.totalItems / payload.limit) || 0);
    }
  };

  const dropdownBreeds = async () => {
    const response = await getAllBreeds();
    if (response.status_code === 200) {
      setBreedData(
        response?.data?.map((item) => ({
          ...item,
          value: item._id,
          label: item.breed_name,
        })) || []
      );
    }
  };

  const addPetData = async (values) => {
    console.log(values, "values from form");
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "pet_image") {
        formData.append("pet_image", values.pet_image[0]);
      } else if (key === "assistance_animal_proof") {
        formData.append(
          "assistance_animal_proof",
          values.assistance_animal_proof[0]
        );
      } else if (key === "pet_breed") {
        formData.append("pet_breed", JSON.stringify(values.pet_breed));
      } else {
        formData.append(key, values[key]);
      }
    });
    formData.append("userId", id);
    const response = await onAddPets(formData);
    if (response.status_code === 200) {
      // navigate("/dashboard/user-management")
      setOpen(false);
    } else {
      // errorToast(response?.message || "Something Went Wrong")
    }
  };

  const stateDataList = async () => {
    const response = await stateLists();
    if (response.status_code === 200) {
      setStateData(response?.data || []);
    }
  };

  const viewPets = async (id) => {
    let payload = {
      id,
    };
    const response = await viewPet(payload);
    if (response?.status_code == 200) {
      setPetData(response?.data || {});
      Object.keys(response?.data).forEach((key) => {
        if (key === "pet_breed") {
          let tempArr = [];
          response?.data?.pet_breed?.map((breed, ind) => {
            tempArr.push({ label: breed.breed_name, value: breed.id });
          });
          setSelectedOptions(tempArr);

          setValue(
            "pet_breed",
            response?.data?.pet_breed.flatMap((item) => item.id) || []
          );
        } else {
          setValue(key, response?.data[key]);
        }
      });
    }
  };

  const addPet = () => {
    setOpen(true);
    setViewState(false);
    reset();
    setEditable(false);
    setSelectedOptions([]);
  };

  const editPetApi = async (values) => {
    let formData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "pet_image") {
        formData.append(
          "pet_image",
          typeof values.pet_image === "string"
            ? values.pet_image
            : values.pet_image[0]
        );
      } else if (key === "assistance_animal_proof") {
        formData.append(
          "assistance_animal_proof",
          typeof values.assistance_animal_proof === "string"
            ? values.assistance_animal_proof
            : values.assistance_animal_proof[0]
        );
      } else if (key === "pet_breed") {
        formData.append("pet_breed", JSON.stringify(values.pet_breed));
      } else {
        formData.append(key, values[key]);
      }
      formData.append("petId", editId);
    });
    const response = await onEditPet(formData);
    if (response.status_code === 200) {
      // successToast(response?.message || "")
      // navigate("/dashboard/user-management")
      setOpen(false);
    } else {
      // errorToast(response?.message || "Something Went Wrong")
    }
  };

  const editPetFunction = (id) => {
    if (editRoute) {
      setOpen(true);
      setEditable(true);
      setEditId(id);
      viewPets(id);
    } else errorToast("Not editable");
  };

  const onSubmit = handleSubmit((values) => {
    if (editable) {
      editPetApi(values);
    } else {
      addPetData(values);
    }
  });

  return (
    <>
      {editRoute || viewRoute ? (
        <>
          <div className="d-flex justify-content-between p-3">
            <h2 className="text-[20px] font-bold text-[#303637]">Pets</h2>
            {!viewRoute && (
              <Button
                type="submit"
                onClick={() => addPet()}
                className="bg-dark text-white p-2 px-4"
              >
                Add Pet
              </Button>
            )}
          </div>
          <table className="w-full min-w-max  table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100   px-3 text-start py-8"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {petsList.map((item, index) => {
                const isLast = index === petsList.length - 1;
                const classes = isLast
                  ? "py-6 px-3 text-center "
                  : "py-6 px-3 border-b  text-center   border-blue-gray-50";

                return (
                  <tr key={index}>
                    <td className={classes}>
                      <img
                        src={item?.pet_image || images.genericPet}
                        alt=""
                        className={`w-10 h-10 rounded-full `}
                      />
                    </td>
                    <td
                      className={classes}
                      onClick={() => {
                        petDetail(item?._id);
                      }}
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal cursor-pointer"
                      >
                        {item?.pet_name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {item?.pet_type || "N/A"}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        // as="a"
                        // href="#"
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {item?.pet_weight}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        {item?.assistance_animal_proof ? (
                          <img
                            src={
                              item?.assistance_animal_proof || images.genericPet
                            }
                            alt=""
                            className="w-10 h-10 rounded-full "
                          />
                        ) : (
                          <p>NA</p>
                        )}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal px-3 py-1 rounded-[100px] text-center bg-[#B9F6CA]"
                      >
                        {item?.pet_liability_signature == true ? "Yes" : "NO"}
                      </Typography>
                    </td>
                    <td>
                      <FontAwesomeIcon
                        onClick={() => {
                          editPetFunction(item?._id);
                        }}
                        className="edit-btn ms-3"
                        icon={faPen}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {petsList.length == 0 && <NoDataFound />}
          {open && (
            <PopUpLayout setShowModal={setOpen}>
              <>
                <form onSubmit={onSubmit}>
                  <div className="bg-white w-[500px] pet-modal p-5 hide_scroll rounded-xl position-relative z_index">
                    <div className="flex justify-between ">
                      <h1 className="taxt-[#303637] text-[20px] font-semibold ">
                        Pet Details
                      </h1>
                      <svg
                        className="cursor-pointer"
                        onClick={() => {
                          setOpen(false);
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M4.39705 4.55379L4.46967 4.46967C4.73594 4.2034 5.1526 4.1792 5.44621 4.39705L5.53033 4.46967L12 10.939L18.4697 4.46967C18.7626 4.17678 19.2374 4.17678 19.5303 4.46967C19.8232 4.76256 19.8232 5.23744 19.5303 5.53033L13.061 12L19.5303 18.4697C19.7966 18.7359 19.8208 19.1526 19.6029 19.4462L19.5303 19.5303C19.2641 19.7966 18.8474 19.8208 18.5538 19.6029L18.4697 19.5303L12 13.061L5.53033 19.5303C5.23744 19.8232 4.76256 19.8232 4.46967 19.5303C4.17678 19.2374 4.17678 18.7626 4.46967 18.4697L10.939 12L4.46967 5.53033C4.2034 5.26406 4.1792 4.8474 4.39705 4.55379L4.46967 4.46967L4.39705 4.55379Z"
                          fill="#212121"
                        />
                      </svg>
                    </div>
                    <div className="mt-3">
                      <div className="row">
                        <div className="col-md-5 ">
                          <ImageUpload
                            className="profile_Image rounded-[90px]"
                            inputId={`pet_profileimage`}
                            imgId={`pet_profilepic`}
                            src={petData?.pet_image || images.genericPet}
                            name={`pet_image`}
                            setValue={setValue}
                            register={register}
                            type="upload"
                            trigger={trigger}
                          />
                        </div>
                        <div className="col-md-7"></div>
                      </div>
                    </div>
                    <div className=" flex w-[100%]  flex-col items-end gap-4 ">
                      {/* <div className="w-[100%]  items-end ">
                        <CustomLabel label={"Animal type"} />

                        <div className="flex flex-col items-end ">
                          <Form.Select
                            className="form-control"
                            aria-label="Animal Type"
                            // onChange={(e) => setStatus(e.target.value)}
                            {...register("pet_type")}
                          >
                            <option value="">Choose Type</option>
                            <option value="Cat">Cat</option>
                            <option value="Dog">Dog</option>
                          </Form.Select>
                          <Error
                            error={errors?.pet_type}
                            message={errors?.pet_type?.message}
                          />
                        </div>
                      </div> */}

                      <div className="w-[100%]  items-end ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Animal type"}
                        />

                        <div className="flex flex-col items-end ">
                          <Form.Select
                            className="form-control"
                            aria-label="Animal Type"
                            // onChange={(e) => setStatus(e.target.value)}
                            {...register("pet_type")}
                          >
                            <option value="">Choose Type</option>
                            <option value="Cat">Cat</option>
                            <option value="Dog">Dog</option>
                          </Form.Select>
                        </div>
                        <Error
                          error={errors?.pet_type}
                          message={errors?.pet_type?.message}
                        />
                      </div>

                      <div className="w-[100%] ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Pet name"}
                        />
                        {/* <CustomerManagementInput /> */}
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Pet Name"
                          {...register("pet_name")}
                        />
                        <Error
                          error={errors?.pet_name}
                          message={errors?.pet_name?.message}
                        />
                      </div>

                      {/*  */}

                      {/*  */}
                      <div className="w-[100%]  items-end ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Choose State"}
                        />

                        <div className="flex flex-col items-end ">
                          <Form.Select
                            className="form-control"
                            aria-label="Choose State"
                            // onChange={(e) => setStatus(e.target.value)}
                            {...register("state")}
                          >
                            <option value="">Choose State</option>
                            {stateData?.map((item, index) => (
                              <option value={item?._id}>
                                {item?.state_name}
                              </option>
                            ))}
                            {/* <option value="Cate">Cat</option> */}
                          </Form.Select>
                        </div>
                        <Error
                          error={errors?.state}
                          message={errors?.state?.message}
                        />
                      </div>
                      <div className="w-full">
                        <CustomLabel
                          className="font-semibold"
                          label={"Breed"}
                        />
                        <Select
                          options={breedData}
                          isMulti
                          closeMenuOnSelect={false}
                          value={selectedOptions}
                          onChange={handleChange}
                          placeholder="Select Pet's Breed"
                          className="basic-multi-select multiple-select-2"
                          classNamePrefix="select"
                          defaultValue=""
                        />
                        <Error
                          error={errors?.pet_breed}
                          message={errors?.pet_breed?.message}
                        />
                      </div>
                      <div className="w-full">
                        <CustomLabel
                          className="font-semibold"
                          label={"Weight"}
                        />
                        <input
                          className="form-control "
                          type="text"
                          onKeyDown={numberValidation}
                          placeholder="Enter Weight"
                          {...register("pet_weight")}
                        />
                        <Error
                          error={errors?.pet_weight}
                          message={errors?.pet_weight?.message}
                        />
                      </div>
                      <div className="w-full ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Assistance animal"}
                        />
                        <div className="flex flex-col">
                          <ImageUpload
                            className="profile_Image rounded-[35px] h-24"
                            inputId={`pet_assistance_proof`}
                            imgId={`pet_assistance_animal_proof`}
                            src={
                              petData?.assistance_animal_proof ||
                              images.genericPet
                            }
                            name={`assistance_animal_proof`}
                            setValue={setValue}
                            register={register}
                            type="upload"
                            trigger={trigger}
                          />
                        </div>
                      </div>
                      <div className="w-full">
                        <CustomLabel
                          className="font-semibold"
                          label={"Bio (optional)"}
                        />

                        <CustomerManagementTextArea
                          name={"bio"}
                          register={register}
                        />
                        <Error
                          error={errors?.bio}
                          message={errors?.bio?.message}
                        />
                      </div>
                      <div className="w-[100%] ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Pet License No."}
                        />
                        {/* <CustomerManagementInput /> */}
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter License No"
                          {...register("vets_license_no")}
                        />
                        <Error
                          error={errors?.vets_license_no}
                          message={errors?.vets_license_no?.message}
                        />
                      </div>
                      <div className="w-[100%] ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Pet License Date."}
                        />
                        <input
                          className="form-control"
                          type="date"
                          max={currentDate}
                          {...register("vets_license_date")}
                        />
                        <Error
                          error={errors?.vets_license_date}
                          message={errors?.vets_license_date?.message}
                        />
                      </div>
                      <div className="w-[100%] ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Rabbies License Date."}
                        />
                        <input
                          className="form-control"
                          type="date"
                          max={currentDate}
                          placeholder="Enter Rabbies License Date"
                          {...register("rabbies_vaccine_date")}
                        />
                        <Error
                          error={errors?.rabbies_vaccine_date}
                          message={errors?.rabbies_vaccine_date?.message}
                        />
                      </div>
                      <div className="w-[100%] ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Rabbies Vaccine Vailation Date."}
                        />
                        <input
                          className="form-control"
                          type="date"
                          min={currentDate}
                          placeholder="Enter Validataion Date"
                          {...register("rabbies_vaccine_valid_to_date")}
                        />
                        <Error
                          error={errors?.rabbies_vaccine_valid_to_date}
                          message={
                            errors?.rabbies_vaccine_valid_to_date?.message
                          }
                        />
                      </div>
                      <div className="w-[100%] ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Distemper License Date."}
                        />
                        <input
                          className="form-control"
                          type="date"
                          max={currentDate}
                          placeholder="Enter Distemper License Date"
                          {...register("distemper_vaccine_date")}
                        />
                        <Error
                          error={errors?.distemper_vaccine_date}
                          message={errors?.distemper_vaccine_date?.message}
                        />
                      </div>
                      <div className="w-[100%] ">
                        <CustomLabel
                          className="font-semibold"
                          label={"Distemper Vaccine Vailation Date."}
                        />
                        <input
                          className="form-control"
                          type="date"
                          min={currentDate}
                          placeholder="Enter Validataion Date"
                          {...register("distemper_vaccine_valid_to_date")}
                        />
                        <Error
                          error={errors?.distemper_vaccine_valid_to_date}
                          message={
                            errors?.distemper_vaccine_valid_to_date?.message
                          }
                        />
                      </div>
                    </div>
                    {!viewState && (
                      <div className="d-flex justify-content-center gap-4 mt-4">
                        <Button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="bg-white common-btn cancel-btn text-dark p-2 px-4"
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="bg-dark common-btn text-white p-2 px-4"
                        >
                          Save
                        </Button>
                      </div>
                    )}
                  </div>
                </form>
              </>
            </PopUpLayout>
          )}
        </>
      ) : (
        <div className="text-pet-data">
          You can add or see Pet while editing the user
        </div>
      )}
    </>
  );
}
