import React from "react";

const Box = ({ children }) => {
  // console.log()
  return (
    <div className="bg-white h-[100vh] w-[100vw]">
      <div className="    rounded-xl ">
        {children}
      </div>
    </div>
  );
};

export default Box;
