import React from "react";

const ChatUser = ({ chatList, setGroup, sender }) => {
  return (
    <div className="chats-container">
        <div className="one-chat-wrap">
            <div className="one-chat-img">
              <img src="/asset/Person.svg" />
            </div>
            <div className="one-chat-txt">
              <p>Customer Chat</p>
            </div>
          </div>
      {chatList?.map((chat) => (
        <div key={chat?._id} className="user-name-wrap" onClick={()=>setGroup(chat)}>
          <div className="user-img">
            <img src="/asset/chat-user.svg" alt="" />
            <div className="green">
              {/* <img src="/asset/Oval.svg" /> */}
            </div>
          </div>
          <div className="user-txt">
            {/* <h5>{chat?.group_name || "Guest User"}</h5> */}
            <p>{(`${chat?.users?.find(item=>item?._id!==sender)?.name || "Guest User"}: ${chat?.users?.find(item=>item)?.latest_message || "NA"}`)}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatUser;
