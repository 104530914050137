import * as React from "react";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function SelectWithChips() {
  const [chipData, setChipData] = React.useState([
    { key: 0, label: "Angular" },
    { key: 1, label: "jQuery" },
    // { key: 2, label: "Polymer" },
    // { key: 3, label: "React" },
    // { key: 4, label: "Vue.js" },
  ]);

  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
      }}
      component="ul"
      className="items-center  justify-end w-full  !rounded-xl border-[1px] !border-[#9A9A9A] focus:!border-[#9A9A9A] !h-[56px] text-[#9A9A9A] !shadow-none"
    >
      {chipData.map((data) => {
        return (
          <ListItem key={data.key}>
            <Chip
              className="!text-[14px] !font-medium !bg-[#EAEAEA] "
              label={data.label}
              deleteIcon={<ClearIcon className="!text-black " />}
              onDelete={handleDelete}
            />
          </ListItem>
        );
      })}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15.8527 7.64582C16.0484 7.84073 16.0489 8.15731 15.854 8.35292L10.389 13.8374C10.1741 14.0531 9.82477 14.0531 9.60982 13.8374L4.14484 8.35292C3.94993 8.15731 3.95049 7.84073 4.1461 7.64582C4.34171 7.4509 4.65829 7.45147 4.85321 7.64708L9.99942 12.8117L15.1456 7.64708C15.3406 7.45147 15.6571 7.4509 15.8527 7.64582Z"
          fill="#212121"
        />
      </svg>
    </Paper>
  );
}
