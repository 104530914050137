export const keyValues = (e) => {
  if (
    e.key.toLowerCase() === "e" ||
    e.key === "+" ||
    e.key === "-" ||
    e.key === "*" ||
    e.key === "/"
  ) {
    e.preventDefault();
  }
};

export const alphabetValues = (e) => {
  let textRegex = /^[a-zA-Z][a-zA-Z\\s]+$/;
  let numberRegex = /^[0-9*#+$%^&*()_./,<>?}|{":';=-]+$/;
  if (numberRegex.test(e.key) || e.key === "[" || e.key === "]") {
    e.preventDefault();
  }
};

export const numberValidation = (e) => {
  let numberRegex = /^\d+$/;
  if (e.key !== "Backspace" && !numberRegex.test(e.key)) {
    e.preventDefault();
  }
};
