import api from './interceptor';

export const getMembershipList = async (payload) => await api.get(`viewAllMemberships?skip=${payload.skip}&limit=${payload.limit}`);
export const addMember = async (payload) => await api.post(`addMembership`, payload);
export const editMember = async (payload) => await api.post(`editMembership`, payload);
export const getMembershipDetails = async (payload) => await api.get(`viewMembership?id=${payload.id}`);
export const updateMembershipStatus = async (payload) => await api.post(`updateMembership`, payload);


export const getItemList = async (payload) => await api.get(`viewAllItems?skip=${payload.skip}&limit=${payload.limit}`);
export const addItem = async (payload) => await api.post(`addItems`, payload);
export const editItem = async (payload) => await api.post(`editItem`, payload);
export const getItemDetails = async (payload) => await api.get(`viewItem?id=${payload.id}`);
export const updateItemStatus = async (payload) => await api.post(`updateItemStatus`, payload);


export const getBoutiqueList = async (payload) => await api.get(`viewAllBoutique?skip=${payload.skip}&limit=${payload.limit}`);
export const addBoutique = async (payload) => await api.post(`addBoutiqueItem`, payload);
export const editBoutique = async (payload) => await api.post(`editBoutiqueItem`, payload);
export const getBoutiqueDetails = async (payload) => await api.get(`getBoutiqueItem?id=${payload.id}`);
export const updateBoutiqueStatus = async (payload) => await api.post(`updateBoutiqueItemStatus`, payload);

export const addPricing = async (payload) => await api.post(`addPrice`, payload);
export const getPricingList = async (payload) => await api.get(`priceHistory?type=${payload.type}&id=${payload.id}&skip=${payload.skip}&limit=${payload.limit}`);

/*discounts*/

export const getDiscountList = async (payload) => await api.get(`discountList?skip=${payload.skip}&limit=${payload.limit}`);
export const getMembershipData = async (payload) => await api.get(`membershipList`);
export const addDiscountApi = async (payload) => await api.post(`addDiscount`, payload);
export const activeDiscountApi = async (payload) => await api.post(`updateDiscountStatus`, payload);
export const getDiscountDetails = async (payload) => await api.get(`getDiscount?id=${payload.id}`);
export const updateDiscountStatus = async (payload) => await api.post(`editDiscount`, payload);

/*membership*/

export const getUserMembershipList= async (payload) => await api.get(`user_membership_List?skip=${payload.skip}&limit=${payload.limit}`);
export const getHistoryMembershipList= async (payload) => await api.get(`updated_membership_list?skip=${payload.skip}&limit=${payload.limit}`);
export const getUserMebershipDetails = async (payload) => await api.get(`view_user_membership?id=${payload.id}`);
export const updateMembershipDetails = async (payload) => await api.post(`update_user_membership`, payload);
export const getUserMebershipHistoryDetails = async (payload) => await api.get(`view_updated_user_membership?user_membership_id=${payload.user_membership_id}`);
export const getMembershipPrices = async (payload) => await api.get(`membership_price_history?id=${payload.id}`);

export const getSalesList = async (payload) => await api.get(`get_all_sale?skip=${payload.skip}&limit=${payload.limit}`);
export const getItemsBoutique = async () => await api.get(`get_items_and_boutiques`);

export const addSale = async (payload) => await api.post(`add_sale`, payload);
export const editSale = async (payload) => await api.post(`edit_discount_price`, payload);

export const deleteDiscount = async (payload) =>
  await api.post(`deleteDiscount`, payload);
// export const getItemDetails = async (payload) => await api.get(`viewItem?id=${payload.id}`);
// export const updateItemStatus = async (payload) => await api.post(`updateItemStatus`, payload);