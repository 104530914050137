import api from "../interceptor";

// posted jobs

// view all job posted list
export const getAllCareers = async (payload) =>
  await api.get(`/getAllCareers?skip=${payload.skip}&limit=${payload.limit}`);

// view job on id
export const getCareerbyID = async (payload) =>
  await api.get(`/getCareerbyID?id=${payload}`);

// add new Job
export const addCareer = async (payload) =>
  await api.post(`/addCareer`, payload);

// update Job
export const updateCareer = async (payload) =>
  await api.put(`/updateCareer`, payload);

// delete Job
export const deleteCareer = async (payload) =>
  await api.get(`/deleteCareer?id=${payload.id}`);

// applied jobs

// user_job_list
export const userJobsList = async (payload) =>
  await api.get(
    `/user_jobs_list?skip=${payload.skip}&limit=${payload.limit}&search=${payload.search}`
  );
export const getUserJobList = async (payload) =>
  await api.get(`/getUserJob?id=${payload}`);
