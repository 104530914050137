import { Card, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

const TABLE_HEAD = [
  "Preferred Name",
  "Legal Name",
  "Email",
  "Mobile number",
  "Membership",
];

const TABLE_ROWS = [
  {
    name: "John Michael",
    job: "Manager",
    date: "23/04/18",
    status: "In Progress",
  },
];

export function FAQsListing() {
  const navigate = useNavigate();

  return (
    <Card className="h-[calc(100vh-250px)] !z-5 overflow-x-scroll hide_scroll rounded-none   w-[calc(100vw-300px)]">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-t border-blue-gray-100   px-14 text-start py-6"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {TABLE_ROWS.map(({ name, job, date, status }, index) => {
            const isLast = index === TABLE_ROWS.length - 1;
            const classes = isLast
              ? "py-6 px-14"
              : "py-6 px-14 border-b td-dot border-blue-gray-50";

            return (
              <tr key={name}>
                <td className={classes + " cursor-pointer"}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {name}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {job}
                  </Typography>
                </td>
                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {date}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    {job}
                  </Typography>
                </td>

                <td className={classes}>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className={`font-normal ${
                      status == "Scheduled"
                        ? "bg-[#90CAF9]"
                        : status == "Draft"
                        ? "bg-[#FFF8E1]"
                        : status == "In Progress"
                        ? "bg-[#B9F6CA]"
                        : "bg-[#EAEAEA]"
                    }  px-3 py-1 rounded-[100px] text-center`}
                  >
                    {status}
                  </Typography>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
}
