import React, { useState } from "react";
import { Card, Typography } from "@material-tailwind/react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import CustomerDetails from "./user-details/CustomerDetails";
import FlightDetails from "./flight-details/FlightDetails";
import { TransactionDetails } from "./transaction-details/TransactionDetails";
import { PetsDetails } from "./pets-details/PetsDetails";
import person from "../../../../../assets/images/icons/users/Person.png";
import airplane from "../../../../../assets/images/icons/users/Airplane.png";
import payment from "../../../../../assets/images/icons/users/Payment.png";
import pets from "../../../../../assets/images/icons/users/Transactions Icons.png";
import ItemsManagement from "./items-mgmt/ItemsManagement";
import AccountStatus from "./account-status/AccountStatus";
const UserListTabs = ({ state, setState, setUserDetails }) => {
  const data = [
    {
      label: "Customer details",
      value: "Customer details",
      desc: `It really matters and then like it really doesn't matter.
          What matters is the people who are sparked by it. And the people
          who are like offended by it, it doesn't matter.`,
      icon: person,
    },
    {
      label: "Items Management",
      value: "Items Management",
      desc: `Items managemnt`,
      icon: person,
    },
    {
      label: "Pets",
      value: "Pets",
      desc: `Because it's about motivating the doers. Because I'm here
          to follow my dreams and inspire other people to follow their dreams, too.`,
      icon: pets,
    },
    {
      label: "Flight History",
      value: "Flight History",
      desc: `We're not always in the position that we want to be at.
          We're constantly growing. We're constantly making mistakes. We're
          constantly trying to express ourselves and actualize our dreams.`,
      icon: airplane,
    },
    {
      label: "Transaction Mgmt",
      value: "Transaction Mgmt",
      desc: `Because it's about motivating the doers. Because I'm here
          to follow my dreams and inspire other people to follow their dreams, too.`,
      icon: payment,
    },
    {
      label: "Account Status",
      value: "Account Status",
      desc: `Account Status`,
    },
  ];
  console.log(data);

  return (
    <>
      <Card className=" overflow-x-scroll hide_scroll   rounded-2xl w-[calc(100vw-200px)]">
        <Tabs
          value="Customer details"
          orientation="horizontal"
          className="!bg-white"
        >
          <TabsHeader className=" !bg-white pt-6 !text-start px-1  flex flex-row items-start">
            {data.map(({ label, value, icon }) => (
              <Tab
                key={value}
                value={value}
                className="flex "
                onClick={() => {
                  setState(value);
                }}
              >
                <div className="flex items-center">
                  <span>
                    {/* <img
                      src={icon}
                      {
                        label === "Customer details"
                          ? person
                          : label === "Pets"
                          ? pets
                          : label === "Flight history"
                          ? airplane
                          : payment
                      }
                      alt="imgiconsh"
                    /> */}
                  </span>
                  <span className="self-start font-semibold text-sm">{label}</span>
                </div>
              </Tab>
            ))}
          </TabsHeader>
          <div className="border-r-[1px] border-[#C0C0C0] "></div>
          <TabsBody>
            {data.map(({ value, desc }) => (
              <TabPanel
                key={value}
                value={value}
                className="py-0 px-2 overflow-x-scroll hide_scroll "
              >
                {value === "Customer details" && (
                  <CustomerDetails setUserDetails={setUserDetails} />
                )}
                {value === "Items Management" && <ItemsManagement />}
                {value === "Pets" && <PetsDetails />}

                {value === "Flight History" && <FlightDetails />}

                {value === "Transaction Mgmt>" && <TransactionDetails />}
                {value === "Account Status" && <AccountStatus />}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </Card>
    </>
  );
};

export default UserListTabs;
