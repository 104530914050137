import { button } from "@material-tailwind/react";
import React from "react";
import add_icon from "../../../assets/images/icons/plus_icon.png";
import { useNavigate } from "react-router-dom";
import { Select, Option } from "@material-tailwind/react";
import { InputAdornment, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

const ContainerHeading = ({
  className = "",
  title = "",
  chipName = "",
  bgColor = "#fff",
  button,
  redirect,
  onButtonClick = () => { },
  search = false,
  searchValue = "",
  setSearchValue = () => { },
  dropdownSelect,
  options,
  setSelected = () => { },
}) => {
  // react-router navigate function
  const navigate = useNavigate();

  // clear search
  const clearSearch = () => {
    setSearchValue("");
  };

  // button click handler
  const handleButtonClick = () => {
    if (redirect) {
      navigate(redirect);
    } else if (onButtonClick) {
      onButtonClick();
    } else return;
  };

  // input handler
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  // select function for dropdown select
  const handleSelect = (e) => {
    setSelected(e);
  };
  return (
    <>
      <div
        id="WrapperRoot"
        className={`${className} bg-white flex flex-row justify-between w-full h-17 items-start p-3  `}
      >
        {/* left container */}
        <div>
          <div className="text-xl font-['Hauora'] font-bold text-[#2e2e2e]">
            {title || "Alfonso Philips"}
          </div>
        </div>

        {/* right container */}
        <div className="flex flex-row justify-center align-middle items-center gap-2">
          {/* search */}
          {search && (
            <>
              <TextField
                size="small"
                className="rounded-lg"
                id="input-with-icon-textfield"
                placeholder="Search"
                value={searchValue}
                onChange={handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className="rounded-lg">
                      <Search className="rounded-lg" />
                    </InputAdornment>
                  ),

                  endAdornment: searchValue && (
                    <InputAdornment position="end">
                      <CloseIcon onClick={clearSearch} cursor="pointer" />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
            </>
          )}
          {/* select dropdown */}
          {dropdownSelect && (
            <>
              <div className="w-43">
                <Select
                  className="capitalize rounded-lg "
                  label="Select Type"
                  onChange={handleSelect}
                >
                  {options?.map((option, option_index) => {
                    return (
                      <Option
                        className="capitalize"
                        key={option_index}
                        value={option?.value}
                      >
                        {option?.label || option?.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </>
          )}

          {/* button */}
          {button && (
            <>
              <div
                className=" bg-black justify-center items-stretch bg-neutral-900 flex px-2 py-2.5 gap-1 rounded-lg cursor-pointer w-[auto] h-[38px]"
                onClick={handleButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.7498 3C12.1295 3 12.4434 3.28201 12.4931 3.64808L12.5 3.74985L12.5012 11H19.7543C20.1685 11 20.5043 11.3358 20.5043 11.75C20.5043 12.1297 20.2221 12.4435 19.8561 12.4932L19.7543 12.5H12.5012L12.5032 19.7491C12.5033 20.1633 12.1676 20.4993 11.7534 20.4993C11.3737 20.4993 11.0598 20.2173 11.0101 19.8512L11.0032 19.7494L11.0012 12.5H3.7522C3.33798 12.5 3.0022 12.1642 3.0022 11.75C3.0022 11.3703 3.28435 11.0565 3.65043 11.0068L3.7522 11H11.0012L11 3.75015C10.9999 3.33594 11.3356 3 11.7498 3Z"
                    fill="white"
                  />
                </svg>
                <div className="text-white text-center text-sm font-medium leading-5 self-start grow whitespace-nowrap my-auto">
                  {button || "Add"}
                </div>
              </div>
            </>
          )}

          {/* chips */}
          {chipName && (
            <>
              <div
                id="Chip"
                className={`text-sm font-['Hauora'] leading-[20px] text-[#2e2e2e] bg-[${bgColor}] flex flex-row justify-center mt-1 py-1 w-[100px] items-start rounded-[100px]
  `}
              >
                {chipName || "Member"}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ContainerHeading;
