import { BrowserRouter } from "react-router-dom";
import Path from "./routes/Path";
import "./style/styles.css";
import "./style/index.scss";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";

function App() {
  const { loader } = useSelector((state) => state.loader);

  // getToken(messaging, {
  //   vapidKey:
  //     "BKjgHBLSAZ_LNliEl2p7VRd0BkvaFP67LVMZb732w7w1aUSf8R1pal-Jn5ASWMlvxnanzlZg7brkCWxgoOHScwA",
  // })
  //   .then((currentToken) => {
  //     if (currentToken) {
  //       console.log(currentToken);
  //     } else {
  //       // Show permission request UI
  //       Notification.requestPermission().then((permission) => {
  //         if (permission === "granted") {
  //           console.log("Notification permission granted.");
  //         }
  //       });
  //       console.log(
  //         "No registration token available. Request permission to generate one."
  //       );
  //       // ...
  //     }
  //   })
  //   .catch((err) => {
  //     console.log("An error occurred while retrieving token. ", err);
  //     // ...
  //   });
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />

      <Path />
    </>
  );
}

export default App;
