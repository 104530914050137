import React from 'react'

const Error = ({ error=false, message="" }) => {
  return (
    <> {error && <div className="input-field-error">
    {message || error?.message}
</div>}</>
  )
}

export default Error