import React from "react";

const Breadcrumb = ({ parent, child, subchild }) => {
  return (
    <>
      <div
        id="BreadcrumbSmallTextRoot"
        className="bg-white flex flex-row gap-1 w-full h-10 items-start pt-2 px-4 rounded-lg"
      >
        <div
          id="Label"
          className="text-xs font-['Hauora'] leading-[20px] text-[#212121] mt-px"
        >
          {parent || "User list"}
        </div>
        <img
          src="https://file.rendit.io/n/QqFombN9z93Zc5JoYtBC.svg"
          alt="Next"
          id="Next"
          className="w-6"
        />
        <div
          id="Label1"
          className="text-xs font-['Hauora'] leading-[20px] text-[#212121] mt-px"
        >
          {child || "Alfonso Philips"}
        </div>
        {subchild && (
          <>
            <img
              src="https://file.rendit.io/n/QqFombN9z93Zc5JoYtBC.svg"
              alt="Next"
              id="Next"
              className="w-6"
            />
            <div
              id="Label1"
              className="text-xs font-['Hauora'] leading-[20px] text-[#212121] mt-px"
            >
              {subchild || "Alfonso Philips"}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Breadcrumb;
