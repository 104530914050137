import moment from "moment";

import { uploadFileImage } from "../api/flight-management/flight";

export const getDateFormat = (date, format = "DD/MM/YYYY") => {
  return moment(date).format(format);
};

export const uploadImageTemplate =
  "https://as1.ftcdn.net/v2/jpg/04/94/12/10/1000_F_494121047_MWi1oEYNest7yl1CardZO8jFtOwS7aRT.jpg";

export const imageValidation = (value) => {
  if (value.length > 0) {
    return true;
  }
  return false;
};

export const uploadImage = async (payload) => {
  console.log(payload);
  let formData = new FormData();
  formData.append("image", payload);
  const response = await uploadFileImage(formData);
  if (response.status_code === 200) {
    let imageurl = response?.data;
    if (imageurl.length === 1) {
      return imageurl.toString(); // Return imageurl as a string if its length is 1
    } else {
      return imageurl; // Return imageurl as it is if its length is not 1
    }
  }
};

export const payloadToString = (payload = {}) => {
  return Object.keys(payload)?.reduce((acc, item) => `${acc}${payload[item] ? `&${item}=${encodeURIComponent(payload[item])}` : ""}`, "")
}
